import axios from 'axios'

export default class MajorService {

    getMajors() {
        return axios.get('assets/layout/data/second-class.json').then(res => res.data.data);
    }
	
	getMajorsNameAndId() {
	    return axios.get('assets/layout/data/second-class-name.json').then(res => res.data.data);
	}
	
	getJournalNameAndId() {
	    return axios.get('assets/layout/data/journals-name.json').then(res => res.data.data);
	}
	
	getChargePicsAndFileNumber() {
	    return axios.get('assets/layout/data/charge-and-file.json').then(res => res.data.data);
	}
}
