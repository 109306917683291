export default class FilterWordsService {

	highLightWord(keyword, wordlist) {
		let filterArr = [];
		// 过滤出符合条件的值
		wordlist.forEach(item => {
			if (String(item).toLowerCase().includes(keyword) || item.includes(keyword)) {
				filterArr.push(this.joinHighlight(item, keyword));
			}
		});
		return filterArr.slice(0, 15);
	}
	
	
	joinHighlight(str, key) {
		var reg = new RegExp((`(${key})`), "i");
		var replace = '<span style="color:#0081ff;font-weight:bold;">$1</span>';
		return str.replace(reg, replace);
	}
	//新旧单词表合并
	joinWordlist(old_word_list, page_content) {
		//只对英文分词，不对中文分词
		// var new_word_list=page_content.split(' ');
		var new_word_list=page_content.match(/[a-zA-z0-9]+/ig);
		if (new_word_list==null){
			new_word_list=page_content.split(' ');
		}
		for(var i=0;i<new_word_list.length;i++){
			if(old_word_list.indexOf(new_word_list[i])==-1 && new_word_list[i].length>3){
				old_word_list.push(new_word_list[i])
			}
		}
		return old_word_list;
	}
	
	joinFigNameAndFigNum(sents){
		// var reg=/fig.?[0123456789 .]+$|figure.?[0123456789 .]+$|图.?[0123456789 .]+$/g;
		// // var reg=/fig. ?[0123456789 .]+$/g;
		// var new_sents=[];
		// for (var i=1;i<sents.length;i++){
		// 	var this_sent=sents[i-1]+sents[i];
		// 	var fig_name=this_sent.toLowerCase().search(reg);
		// 	console.log(fig_name);
		// 	if(fig_name.length!=0){
		// 		new_sents.push(this_sent.replace(fig_name,''));
		// 		new_sents.push(fig_name+sents[i+1]);
		// 	}
		// }
		
		var new_sents=[];
		if (sents.length==1){
			return sents;
		}else{
			for (var i=0;i<sents.length-1;i++){
				if(sents[i+1].length<5){
					var this_sent=sents[i]+sents[i+1];
					new_sents.push(this_sent);
					i++;
				}else{
					new_sents.push(sents[i]);
					if((i+1)==sents.length-1 && sents[i+1].length>=5){
						new_sents.push(sents[i+1]);
					}
				}
			}
			return new_sents;
		}
	}
	
	//断句和断词
	splitSents(page_content){
		if (page_content.length==0){
			return [];
		}
		var reg = new RegExp('([。！？]|[.?!] )', 'g');
		var sents=page_content.split(reg);
		var split_chars=["。","？","！","? ","! ",". "]
		var new_sents=[];
		for (var i=0;i<sents.length;i++){
			if(split_chars.indexOf(sents[i])!=-1 && i!=0){
				var this_sent=sents[i-1]+sents[i];
				new_sents.push(this_sent);
			}else{
				if(i==sents.length-1){
					new_sents.push(sents[i]);
				}
			}
		}
		var final_sents=this.joinFigNameAndFigNum(new_sents);
		return final_sents;
	}
	
}
