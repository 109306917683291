<template>
	<Dialog :style="{width: '400px'}" header="上传文件" :modal="true">
		<div style="display: flex;align-items: center;justify-content: center;">
			<iframe ref='uploadFileHtml'
				:src="'duploadprograss/testpage.html?####'+total_size+'####'+time_stamp+'####'+sign+'####'+upload_url"
				frameborder="0" style="height: 25rem;width:28.125rem;"></iframe>
		</div>
	</Dialog>
</template>

<script>
	import NetWorkService from '../../service/NetWorkService.js';
	export default {
		name: '',
		props: {
			upload_url_copy: {
				default: null,
				type: String,
			}
		},
		components: {
			
		},
		data() {
			return {
				upload_url: this.upload_url_copy, //此后para可在methods中操作
				time_stamp:'',
				sign:'',
				total_size:'200',
			}
		},
		networkService: null,
		created() {
			this.networkService = new NetWorkService();
		},
		mounted() {
			// console.log('组件加载成功');
			this.time_stamp=this.networkService.getTimeStamp();
			this.sign=this.networkService.LL(this.time_stamp);
		},
		methods: {
			uploadPasteImg(base64){
				this.$refs.uploadFileHtml.contentWindow.uploadPasetImgFile(base64,this.time_stamp,this.sign);
			}
		},
	}
</script>

<style scoped lang="scss">
</style>
