import { createRouter, createWebHashHistory } from 'vue-router';
import readPaper from './pages/ReadPaper.vue';

const routes = [
    {
        path: '/',
        name: 'readPaper',
		meta: {title: "读文献" ,  keepAlive:true},
        component: readPaper,
    },
	// {
	//     path: '/readPaper',
	//     name: 'readPaper',
	// 	meta: {title: "读文献" ,  keepAlive:true},
	//     component: () => import('./pages/ReadPaper.vue'),
	// },
	{
	    path: '/checkwordlist',
	    name: 'checkwordlist',
		meta: {title: "单词本" ,  keepAlive:true},
	    component: () => import('./pages/CheckWordList.vue'),
	},
	{
	    path: '/searchJournalIms',
	    name: 'searchJournalIms',
		meta: {title: "查图片" ,  keepAlive:true},
	    component: () => import('./pages/SearchJournalIms.vue'),
	},
	{
	    path: '/essayPolishing',
	    name: 'essayPolishing',
		meta: {title: "查原文" ,  keepAlive:true},
	    component: () => import('./pages/EssayPolishing.vue'),
	},
	{
	    path: '/login',
	    name: 'login',
	    component: () => import('./pages/Login.vue'),
	},
	{
	    path: '/useraccount',
	    name: 'useraccount',
	    component: () => import('./pages/user/UserAccount.vue'),
	},
	{
	    path: '/helpAndFeedBack',
	    name: 'helpAndFeedBack',
	    component: () => import('./pages/user/helpAndFeedBack.vue'),
	},
	{
	    path: '/managePapers',
	    name: 'managePapers',
		meta: {title: "文献管理" ,  keepAlive:true},
	    component: () => import('./pages/localPapers/managePapers.vue'),
	},
	{
	    path: '/editWord',
	    name: 'editWord',
	    component: () => import('./pages/userComponents/EditWord.vue'),
	},
	{
	    path: '/emailLogin',
	    name: 'emailLogin',
	    component: () => import('./pages/EmailLogin.vue'),
	},
	{
	    path: '/transPage',
	    name: 'transPage',
	    component: () => import('./pages/transPage.vue'),
	},
	{
	    path: '/translateCard',
	    name: 'translateCard',
	    component: () => import('./pages/userComponents/translateCard.vue'),
	},
	{
	    path: '/noteStorage',
	    name: 'noteStorage',
	    component: () => import('./pages/notes/noteStorage.vue'),
	},
	{
	    path: '/noteEditor',
	    name: 'noteEditor',
		meta: {title: "写笔记" ,  keepAlive:true},
	    component: () => import('./pages/notes/noteEditor.vue'),
	},
	{
	    path: '/noteList',
	    name: 'noteList',
	    component: () => import('./pages/notes/noteList.vue'),
	},
	{
	    path: '/notesOperator',
	    name: 'notesOperator',
	    component: () => import('./pages/notes/notesOperator.vue'),
	},
	{
	    path: '/uploadFile',
	    name: 'uploadFile',
	    component: () => import('./pages/userComponents/uploadFile.vue'),
	},
	{
	    path: '/newFileName',
	    name: 'newFileName',
	    component: () => import('./pages/userComponents/newFileName.vue'),
	},
	{
	    path: '/selectBackColor',
	    name: 'selectBackColor',
	    component: () => import('./pages/userComponents/selectBackColor.vue'),
	},
	{
	    path: '/docConvert',
	    name: 'docConvert',
	    component: () => import('./pages/DocConvert.vue'),
	},
	{
	    path: '/checkIn',
	    name: 'checkIn',
	    component: () => import('./pages/CheckIn.vue'),
	},
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

export default router;
