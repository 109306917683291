<template>
	<Toast />
	<div>
		<!-- //编辑对象 -->
		<Dialog v-model:visible="productDialog" :style="{width: '450px'}" header="单词对象" :modal="true" class="p-fluid">
			<div class="p-formgrid p-grid">
				<div class="p-field p-col-3">
					<Button label="查询" icon="pi pi-search" title="查询数据库中释义" class="p-button-secondary" @click='searchTransForWord' />
				</div>
				<div class="p-field p-col-9">
					<InputText :disabled='disabled_or' v-on:keyup.enter="searchTransForWord" id="or_input" :value="product.or" placeholder="输入原文" />
				</div>
			</div>
			<div v-if='show_main_container'>
				<div class='card-gray'>
					<div class="p-formgrid p-grid translation-item"
						style="font-weight: 700;padding: 10px 0 10px 0;background-color:#f8f9fa;">
						<div class='p-col-6'>释义</div>
						<div class='p-col-4'>来源</div>
						<!-- <div class='p-col-2'>SCI词频</div> -->
						<div class='p-col-2'>操作</div>
						<!-- <div class='p-col-1'>优先级</div> -->
					</div>
					<div class="translation-item" v-for="(item, j) of product.tl" :key="j">
						<div class='p-col-6'>{{item.w}}</div>
						<div v-if='item.mjid_from.length==1' class='p-col-4'>{{item.mjid_from[0].fr}}</div>
						<div v-if='item.mjid_from.length>1' class='p-col-4' style="padding: 0;">
							<Dropdown v-model="item.sl_mjid_from" :options="item.mjid_from" optionLabel="fr"
								placeholder="来源" />
						</div>
						<div class='p-col-2'
							style="display: flex;flex-direction: row;color: var(--primary-color);padding:10px;">
							<i class="pi pi-trash" style="margin-left: 5px;" title="删除" v-if='product.tl.length!=1'
								@click="deleteTransIndex(j)" />
							<i class="pi pi-arrow-up" title="上移当前翻译" style="margin-left: 10px;" @click="upTrans(j)" v-if='j>0' />
						</div>
					</div>
				</div>

				<div class="p-formgrid p-grid">
					<div class="p-field p-col-9">
						<InputText id='new_trans' v-model="new_trans" v-on:keyup.enter="addNewTranslatinToProduct"
							placeholder="输入释义" />
					</div>
					<div class="p-field p-col-3">
						<Button label="添加" icon="pi pi-plus" class="p-button-info" title="添加当前专业和释义" @click="addNewTranslatinToProduct" />
					</div>
				</div>
				<WordFilterInput ref='majorSelected' v-if='majors.length!==0' :list_copy='majors'
					:selected_words_copy='selected_major_name' :filter_input_id_copy="'select_tmp_major_for_word'" />
			</div>
			<template #footer v-if='show_main_container'>
				<Button label="取消" icon="pi pi-times" title="取消修改" class="p-button-text" @click="hideDialog" />
				<Button label="保存" icon="pi pi-check" title="保存修改到云端" class="p-button-text" @click="saveProduct" />
			</template>
		</Dialog>
	</div>
</template>

<script>
	// import TranslateService from '../../service/TransLateService.js';
	// import FilterWordsService from '../../service/FilterWordsService.js';
	import WordFilterInput from './WordFilterInput.vue';
	import NetWorkService from '../../service/NetWorkService.js';
	import WordManagerHelp from '../../service/WordManagerHelp.js';
	import MajorService from '../../service/MajorService.js';
	export default {
		name: 'EditWord',
		props: {
			product_copy: {
				default: null,
				type: Object
			},
			showDialog: {
				default: true,
				type: Boolean,
			},
			disabled_or_copy: {
				default: true,
				type: Boolean,
			}
		},
		components: {
			'WordFilterInput': WordFilterInput,
		},
		watch: {
			productDialog() {
				// console.log('当前productDialog值：',this.productDialog);
				if (this.productDialog == false) {
					this.$emit('initialProduct');
				}
			}
		},
		data() {
			return {
				productDialog: this.showDialog,
				product: this.product_copy,
				disabled_or: this.disabled_or_copy,
				show_main_container: this.disabled_or_copy,
				selected_major_name: null,
				current_tl: [],
				submitted: false,
				selectedProducts: [],
				processing: false,
				majors: [],
				major: null,
			}
		},
		wordManagerHelp: null,
		networkService: null,
		majorService: null,
		created() {
			this.networkService = new NetWorkService();
			this.wordManagerHelp = new WordManagerHelp();
			this.majorService = new MajorService();
		},
		mounted() {
			// console.log('EditWord组件加载成功：', this.product);
			// console.log('显示：', this.productDialog);
			this.majorService.getMajorsNameAndId().then(data => {
				this.majors = data;
				//专业
				this.selected_major_name = this.$appState.user_info.major;
				// console.log('专业：', data);
			});
			this.getCurrentTl();
		},
		methods: {
			//查询单词释义
			searchTransForWord() {
				var or_input_cotent = document.getElementById("or_input").value;
				this.translateWordsByLocal(or_input_cotent);
			},
			//查询单词表
			async translateWordsByLocal(content) {
				var data = new URLSearchParams();
				data.append("search_words", content);
				data.append("dictionary_name", this.select_dictionary);
				data.append("search_from_dic", this.search_from_dic);
				data.append("major_name", this.$appState.user_info.major);
				// data.append("second_class_name", this.$appState.second_class.class_name);
				var url = '/paper/search-words';
				// console.log("请求开始");
				var resp = await this.networkService.postService(url, data);
				this.show_main_container = true;
				this.product = resp.data.words;
				//更新数量
				this.$appState.new_words_num = resp.data.unsaved_count;
				this.processing = false;
			},
			getCurrentTl() {
				//当取消时，重置翻译结果
				for (var i = 0; i < this.product.tl.length; i++) {
					this.current_tl.push(this.product.tl[i]);
				}
			},
			addNewTranslatinToProduct() {
				if (!this.new_trans) return;

				var temp_data = this.getMjid();
				var new_t = {
					"mjid": temp_data['mjid'],
					"mjid_from": temp_data['mjid_from'],
					"sl_mjid_from": temp_data['mjid_from'][0],
					"w": this.new_trans,
				};

				if (this.product.tl[0].w == 'NULL无记录') {
					this.product.tl[0] = new_t;
					this.product.sl = new_t;
				} else {
					this.product.tl.push(new_t);
				}
				this.new_trans = '';
			},
			getMjid() {
				//将专业倒序并组织
				var str_majors = this.$refs.majorSelected.selected_words;
				if (str_majors == '') {
					str_majors = '未标注'
				}
				var words = str_majors.split(' ');
				var or_mjid = words.reverse();
				var mjid = [];
				var mjid_from = [];
				for (var i = 0; i < or_mjid.length; i++) {
					var this_id = or_mjid[i];
					if (this_id != '') {
						mjid_from.push({
							'fr': this_id
						});
						mjid.push(this_id)
					}
				}
				var temp_data = {
					'mjid': mjid,
					'mjid_from': mjid_from
				}
				return temp_data
			},
			deleteTransIndex(index) {
				this.product.tl.splice(index, 1);
			},
			hideDialog() {
				//取消后重置修改的单词列表
				this.product.tl = this.current_tl;
				this.product.sl = this.current_tl[0];
				this.product = {};
				this.current_tl = [];
				this.productDialog = false;
				this.submitted = false;
			},
			//将翻译的顺序上移
			upTrans(index) {
				if (index == 0) {
					return;
				}
				var old_tl = this.product.tl[index - 1];
				this.product.tl[index - 1] = this.product.tl[index];
				this.product.tl[index] = old_tl;
			},
			saveProduct() {
				this.submitted = true;
				if (!this.product.or) {
					this.$toast.add({
						severity: 'error',
						summary: '保存失败',
						detail: '请输入原文',
						life: 3000
					});
					return;
				}
				if (this.product.tl.length == 0) {
					this.$toast.add({
						severity: 'error',
						summary: '保存失败',
						detail: '请添加至少一条释义',
						life: 3000
					});
					return;
				}
				if (this.product.or.trim()) {
					this.product.sl = this.product.tl[0];
					//是针对先前对象的修改则更新对象，是新增对象就新增
					if (!this.product.id) {
						this.product.id = this.wordManagerHelp.createId();
					}
					//保存词典
					this.selectedProducts = [this.product];
					this.uploadWords();

					this.productDialog = false;
					this.product = {};
				}
			},
			async uploadWords() {
				this.processing = true;
				var data = new URLSearchParams();
				data.append("upload_words", JSON.stringify(this.selectedProducts));
				var url = '/paper/upload-personal-words';
				// console.log("请求开始");
				var resp = await this.networkService.postService(url, data);
				if (resp.code == 200) {
					this.$toast.add({
						severity: 'success',
						summary: '成功',
						detail: '更新选中单词成功',
						life: 3000
					});

					this.$emit('reflushProduts');
					this.$emit('initialProduct');
				}
				this.selectedProducts = null;
			},
		}
	}
</script>

<style>
	.translation-item {
		display: flex;
		align-items: center;
		border-top: 1px solid #ededed;
		padding: 2px 0px 2px 0px;
	}
</style>
