<template>
	<!-- <div class="card-gray" style="display: flex;flex-direction: row;justify-content:space-between;"> -->
	<div class="card-gray" style="display: flex;flex-wrap: wrap;justify-content:space-between;">
		<div>
			<div class="p-inputgroup" style="width:150px;" title='输入页码回车后跳转'>
				<InputText :id="input_box_id" style="font-size: 14px;" v-on:keyup.enter="goStatrtSearch"
					@input="pageNumInput" :value="current_page_num" type="number" placeholder="跳转页码" />
				<Tag class="p-mr-2" style="font-size: 14px;" severity="info" :value="'/'+total_page_num+' 页'"></Tag>
			</div>
		</div>
		<div v-if='op_type_copy==0 || op_type_copy==1'>
			<Button label="" icon="pi pi-arrow-left" :disabled="current_page_num==1 || current_page_num>total_page_num"
				class="p-button-info" @click="getNeighborPage(-1)" style="margin-right:.25em" title="上一页" />
			<Button label="" icon="pi pi-arrow-right" :disabled="current_page_num>=total_page_num" class="p-button-info"
				@click="getNeighborPage(1)" style="margin-right:.25em" title="下一页" />
			<Button v-if='op_type_copy==1' label="" icon="pi pi-times" class="p-button-warning" @click="InitialWords"
				style="margin-right:.25em" title="清空查询关键词" />
			<Button v-if='op_type_copy==1' label="" icon="pi pi-search" class="p-button-success" @click="initialSearch" title="查询" />
		</div>
		<div v-if='op_type_copy==2'>
			<Button label="" icon="pi pi-arrow-left" :disabled="current_page_num==1 || current_page_num>total_page_num"
				class="p-button-info" @click="getNeighborPdf(-1)" style="margin-right:.25em"  title="上一页" />
			<Button label="" icon="pi pi-arrow-right" :disabled="current_page_num>=total_page_num" class="p-button-info"
				@click="getNeighborPdf(1)" style="margin-right:.25em"  title="下一页" />
			<Button label="退出" icon="pi pi-upload" class="p-button-danger" @click="hidePageReader" title="返回来源页" />
		</div>
	</div>
</template>

<script>
	import NetWorkService from '../../service/NetWorkService.js';
	export default {
		name: 'pageNumInput',
		props: {
			op_type_copy:{
				default:null,
				type:Number,
			},
			input_box_id:{
				default:'page_num_input',
				type:String,
			}
		},
		components: {

		},
		data() {
			return {
				current_page_num: 1,
				total_page_num: 1,
			}
		},
		networkService: null,
		created() {
			this.networkService = new NetWorkService();
		},
		mounted() {

		},
		methods: {
			pageNumInput() {
				var page_num_input = document.getElementById(this.input_box_id);
				var keyword = page_num_input.value;
				if (keyword == '') {
					this.current_page_num = '';
					// console.log('输入为空');
				} else {
					this.current_page_num = parseInt(keyword);
				}
			},
			//周围页
			getNeighborPage(delta) {
				this.current_page_num = parseInt(this.current_page_num) + delta;
				this.goStatrtSearch();
			},
			//回车后发起搜索
			goStatrtSearch() {
				if (this.current_page_num < 1 || this.current_page_num > this.total_page_num || this.current_page_num ==
					'') {
					this.current_page_num = 1;
					document.getElementById(this.input_box_id).value = 1;
					return;
				}
				this.$emit('startSearch')
			},
			InitialWords() {
				this.current_page_num = 1;
				this.total_page_num = 1;
				this.$emit('InitialWords')
			},
			//点击搜索框刷新页面
			initialSearch() {
				this.current_page_num = 1;
				this.total_page_num = 1;
				this.goStatrtSearch();
			},
			//获取周围页的PDF
			getNeighborPdf(delta){
				this.current_page_num = parseInt(this.current_page_num) + delta;
				this.$emit('getNeighborPdf',delta);
			},
			hidePageReader(){
				this.$emit('hidePaperReader');
			}
		},
	}
</script>

<style scoped lang="scss">
</style>
