import axios from 'axios';
// import EncodeDecodeService from './EncodeDecodeService.js';

export default class TranslateService {
	LL(a) {
		// var k = "";
		var b = 406532644;
		var b1 = 329311072;
	
		var jd = ".";
		var $b = "+-a^+6";
		var Zb = "+-3^+b+-f";
	
		for (var e = [], f = 0, g = 0; g < a.length; g++) {
			var m = a.charCodeAt(g);
			128 > m ? e[f++] = m : (2048 > m ? e[f++] = m >> 6 | 192 : (55296 == (m & 64512) && g + 1 < a.length &&
					56320 == (a.charCodeAt(g + 1) & 64512) ? (m = 65536 + ((m & 1023) << 10) + (a.charCodeAt(++
								g) &
							1023),
						e[f++] = m >> 18 | 240,
						e[f++] = m >> 12 & 63 | 128) : e[f++] = m >> 12 | 224,
					e[f++] = m >> 6 & 63 | 128),
				e[f++] = m & 63 | 128)
		}
		a = b;
		for (f = 0; f < e.length; f++) a += e[f],
			a = this.RL(a, $b);
		a = this.RL(a, Zb);
		a ^= b1 || 0;
		0 > a && (a = (a & 2147483647) + 2147483648);
		a %= 1E6;
		return a.toString() + jd + (a ^ b)
	}
	
	RL(a, b) {
		var t = "a";
		var Yb = "+";
		for (var c = 0; c < b.length - 2; c += 3) {
			var d = b.charAt(c + 2);
			d = d >= t ? d.charCodeAt(0) - 87 : Number(d);
			d = b.charAt(c + 1) == Yb ? a >>> d : a << d;
			a = b.charAt(c) == Yb ? a + d & 42377295 : a ^ d
		}
		return a
	}
	
	getTimeStamp(){
		return new Date().getTime().toString();
	}

	googleTranslate(content, ori_lang, tag_lang) {
		// console.log('去掉句号后',content);
		var base_url='/google'
		var tk = this.TL(content);
		var url =base_url+"/single?client=t" +
			"&sl=" + ori_lang + "&tl=" + tag_lang + "&hl=" + tag_lang + "&dt=at&dt=bd&dt=ex&dt=ld&dt=md&dt=qca" +
			"&dt=rw&dt=rm&dt=ss&dt=t&ie=UTF-8&oe=UTF-8&clearbtn=1&otf=1&pc=1" +
			"&srcrom=0&ssel=0&tsel=0&kc=2&tk=" + tk + "&q=" + content;
		// var headers = {'User-Agent': 'Mozilla/5.0 (Windows NT 6.1; WOW64; rv:23.0) Gecko/20100101 Firefox/23.0'}
		return axios.post(url, {
			headers: {
				'User-Agent': 'Mozilla/5.0 (Windows NT 6.1; WOW64; rv:23.0) Gecko/20100101 Firefox/23.0'
			}
		}).then(res => {
			let sents = res.data[0];
			var result = '';
			for (var i = 0; i < sents.length - 1; i++) {
				// console.log(sents[i][0]);
				result = result + sents[i][0];
			}
			console.log(result);
			return result;
		});
		// console.log('tk');
		// console.log(tk);
		// return tk;
	}
	
	getIP(){
		var posturl='http://pv.sohu.com/cityjson?ie=utf-8';
		return axios.get(posturl).then(res => {
			// console.log('当前IP',res.data);
			return res.data.cip;
		});
	}
	
	m2mTranslate(url,data) {
		var base_url='/firefly-translate'
		// var base_url='http://101.34.116.86:8799/firefly-translate'
	
		var time_stamp=this.getTimeStamp();
		var sign=time_stamp*2-97.31;
		// var sign=time_stamp*2-97;
		var this_header={
		  'Authorization':localStorage.getItem('Authorization')==undefined?'':localStorage.getItem('Authorization'),
		  'TimeStamp':time_stamp,
		  'Sign':sign,
		}
		
		return axios.post(base_url+url,data,{'headers':this_header}).then(res => {
			if (res.data.code==429){
				alert('服务器繁忙，请稍后再试！')
				return {}
			}
			return res.data;
		});

	}
	
	// m2mTranslate(url,data) {
	// 	var base_url='/firefly-translate'
	// 	// postTranslateService
	// 	// var headers = {'User-Agent': 'Mozilla/5.0 (Windows NT 6.1; WOW64; rv:23.0) Gecko/20100101 Firefox/23.0'}
	// 	return axios.post(base_url+url,data).then(res => {
	// 		return res.data;
	// 	});
	// }

	//知网翻译助手
	cnkiQueryDict(content) {
		// var url="https://dict.cnki.net/fyzs-front-api/translate/querytranslatedate";
		console.log('调用知网翻译助手接口');
		var url = "https://dict.cnki.net/old/dict_result.aspx?scw=" + content;
		return axios.get(url).then(res => {
			var html1 = res.data;
			//获取相应meta
			var strs = html1.split('<meta content=')
			var str1 = strs[2].split('name="decription" />')
			//对获得的翻译词条做最后的切分
			var keywords = str1[0].split('||')[0].split('的翻译结果：')[1].split(';')
			// console.log('翻译结果',keywords);

			//对新单词列表构建列表
			var result_list = [];
			for (var i = 0; i < keywords.length; i++) {
				// console.log(sents[i][0]);
				var word_struct = this.createStructForWords(i, keywords[i]);
				result_list.push(word_struct);
			}
			return result_list;
		})
	}

	//deepl翻译接口
	// deeplTranslate(sententce) {
	// 	sentence = '"' + sentence + '"'
	// 	// u_sentence = sentence.encode("unicode_escape").decode()
	// 	data ='{"jsonrpc":"2.0","method": "LMT_handle_jobs","params":{"jobs":[{"kind":"default","raw_en_sentence":' +
	// 		sentence +',"raw_en_context_before":[],"raw_en_context_after":[],"preferred_num_beams":4,"quality":"fast"}],"lang":{"user_preferred_langs":["EN","ZH"],"source_lang_user_selected":"auto","target_lang":"EN"},"priority":-1,"commonJobParams":{},"timestamp":' +
	// 		int(time.time() * 10000).toString() + '},"id":' + random.randint(1, 100000000).toString() + '}';
	// 	r = requests.post('https://www2.deepl.com/jsonrpc',
	// 		headers = {
	// 			'content-type': 'application/json'
	// 		},
	// 		data = data.encode())
	// }
	// //获取时间戳,返回15位毫秒数
	// getTimeStamp() {
	// 	var time = performance.timing.navigationStart * 10 + performance.now() * 10;
	// 	var randi = Math.floor(Math.random() * 100000000);
	// 	var sentence = "上海交通大学";
	// 	// this.code_service=new EncodeDecodeService();
	// 	// var num_utf8=this.code_service.toBytes(sentence)
	// 	// var str_utf8='';
	// 	// for (var i = 0; i < num_utf8.length; i++) {
	// 	// 	str_utf8+=this.getStrOfUtf8(num_utf8[i]);
	// 	// }
	// 	// console.log(str_utf8);
	// 	var data =
	// 		'{"jsonrpc":"2.0","method": "LMT_handle_jobs","params":{"jobs":[{"kind":"default","raw_en_sentence":' +
	// 		'"' + sentence + '"' +
	// 		',"raw_en_context_before":[],"raw_en_context_after":[],"preferred_num_beams":4,"quality":"fast"}],"lang":{"user_preferred_langs":["EN","ZH"],"source_lang_user_selected":"auto","target_lang":"EN"},"priority":-1,"commonJobParams":{},"timestamp":' +
	// 		time.toString() + '},"id":' + randi.toString() + '}';
	// 	console.log(data);
	// 	// var str_u = this.string2unicode(sentence);//\u6211\u662f\u4e2d\u56fd\u4eba
	// 	// console.log(str_utf8);
	// 	// console.log(encodeURI(data));
	// 	var url = 'https://www2.deepl.com/jsonrpc';

	// 	// var unicodeJsonA = JSON.stringify(data); //unicode
	// 	// var jsonA = eval('(' + unicodeJsonA + ')'); //utf-8
		
	// 	// const utf8=require('utf-8');
		
	// 	return axios.post(url, data, {
	// 			headers: {
	// 				'content-type': 'application/json'
	// 			}
	// 		}
	// 	).then(res => {
	// 		console.log(res.join());
	// 	});

	// }

	getStrOfUtf8(num) {
		var utf8list = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "a", "b", "c", "d", "e", "f"];
		var s1m = Math.floor(num / 16);
		var s2m = Math.floor(num - 16 * s1m);
		var str = '\\x' + utf8list[s1m] + utf8list[s2m];
		return str;
	}

	string2unicode(str) {
		var ret = "";
		for (var i = 0; i < str.length; i++) {
			ret += "\\u" + str.charCodeAt(i).toString(16);
		}
		return ret;
	}


	//为新查询结果增加结构
	createStructForWords(index, word) {
		var new_struct = {
			"id": index,
			"name": word,
			"ref": 0,
		}
		return new_struct;
	}
}
