<template>
	<div style="margin-bottom: -20px;width: 100%;">
		<div class="p-inputgroup" id="search_box">
			<InputText :id="filter_input_id" autocomplete="off" style="width: 100%;" :value="selected_words" @focusin="focusIn"
				type="text" @input="InputBlur" placeholder="输入筛选" />
			<Button icon='pi pi-times' class="p-button-secondary" @click.stop="InitialWords" title="清空当前输入" />
			<Button icon='pi pi-chevron-down' @click="changeShowWord" title="关闭筛选列表" />
		</div>
		<div class="card-gray" id="word_container" :style="trans_style"  v-if='show_list'>
			<div class="word-list" id='list-box'>
				<div style="height: 138px;overflow: auto;overflow-x:auto;">
					<ul class="p-autocomplete-items" v-for="(item, j) of filtered_list" :key="j">
						<p class="word-item" @click="selectItem($event, item)" style="padding: 3px 3px 3px 8px;"
							v-html="item"></p>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import NetWorkService from '../../service/NetWorkService.js';
	import FilterWordsService from '../../service/FilterWordsService.js';
	export default {
		name: 'WordFilterInput',
		props: {
			list_copy: {
				default: null,
				type: Array,
			},
			selected_words_copy: {
				default: null,
				type: String,
			},
			filter_input_id_copy:{
				default: 'filter_input',
				type: String,
			},
			op_status_copy:{
				default:'0',
				type:String,
			},
			show_list_copy:{
				default: true,
				type: Boolean,
			}
		},

		data() {
			return {
				op_status:this.op_status_copy,
				filter_input_id:this.filter_input_id_copy,
				list: this.list_copy, //此后para可在methods中操作
				filtered_list: this.list_copy, //筛选后的列表
				selected_words: this.selected_words_copy,
				show_list:this.show_list_copy,
				trans_style: {
					"overflow": "auto",
					"height": "auto",
					"width":"100%",
				},
			}
		},
		networkService: null,
		filterWordsService: null,
		created() {
			this.networkService = new NetWorkService();
			this.filterWordsService = new FilterWordsService();
		},
		mounted() {
			// console.log('组件加载成功:',this.list);
		},
		computed:{

		},
		methods: {
			focusIn() {
				this.filtered_list = this.list;
			},
			//定义是否展示单词列表
			changeShowWord() {
				this.show_list = !this.show_list;
				this.filtered_list = this.list;
			},
			InitialWords() {
				this.selected_words = '';
				this.filtered_list = this.list;
				document.getElementById(this.filter_input_id).value = '';
			},
			//监听单词输入框
			InputBlur() {
				var word_input = document.getElementById(this.filter_input_id);
				var keyword = word_input.value;
				// console.log("输入为：",keyword);
				if (!keyword) {
					this.InitialWords();
					return;
				}
				this.selected_words = keyword;
				var words = this.selected_words.split(' ');
				var new_key = '';
				if (words.length == 1) {
					new_key = this.selected_words;
				} else {
					new_key = words[words.length - 1]
				}
				this.filtered_list = this.filterWordsService.highLightWord(new_key, this.list);
			},
			//选择单词对象
			selectItem(event, item) {
				var new_selected_words = item.replace(/<.*?>/ig, ""); //去除高亮标签
				if(this.op_status=='1'){
					this.selected_words=new_selected_words;
					this.$appState.tmp_major=new_selected_words;
					localStorage.setItem('tmp_major',new_selected_words);
				}else if(this.op_status=='2'){
					this.selected_words=new_selected_words;
					this.$emit('addJournal', new_selected_words);
				}
				else{
					if (!this.selected_words) {
						this.selected_words = new_selected_words + ' ';
					} else {
						var words = this.selected_words.split(' ');
						words[words.length - 1] ='';
						words.unshift(new_selected_words + ' ');
						this.selected_words = words.join(' ');
					}
				}
				// this.filtered_list = this.list;
				// document.getElementById('filter_input').focus();
			},
			recorrectTmpMajor(){
				//修改临时专业
				var word_input = document.getElementById(this.filter_input_id);
				var keyword = word_input.value;
				if (this.list.indexOf(keyword)==-1){
					this.$appState.tmp_major='未选择';
					this.selected_words='未选择';
					localStorage.setItem('tmp_major','未选择');
				}else{
					this.$appState.tmp_major=keyword;
					localStorage.setItem('tmp_major',keyword);
				}
			},
			recorrectUserMajor(){
				//修改临时专业
				// console.log('校正临时专业');
				var word_input = document.getElementById(this.filter_input_id);
				var keyword = word_input.value;
				if (this.list.indexOf(keyword)==-1){
					this.selected_words='未选择';
				}
			}
		}
	}
</script>

<style scoped lang="scss">
	.word-item {}

	.word-item:hover {
		background-color: #1fa1fc;
		color: #ffffff;
	}
</style>
