<template>
	<Toast />
	<div class="card p-grid p-fluid" v-if='false' style="margin-bottom: 0px;">
		<!-- 各种方法的集合库 -->
	</div>
</template>

<script>
	import NetWorkService from '../service/NetWorkService.js';
	import TranslateService from '../service/TransLateService.js';
	export default {
		emits: ['hideLoginContainer','changeUser','changeNoteWrite', 'toggleSetting', 'menuToggle'],
		name: 'Utils',
		props: {
			para_copy: {
				default: null,
				type: Object
			}
		},
		components: {

		},
		data() {
			return{
				select_journal:'Journal_of_Cultural_Heritage',//选中的期刊
				para:this.para_copy, //此后para可在methods中操作
			}
		},
		networkService:null,
		translateService:null,
		created() {
			this.networkService = new NetWorkService();
			this.translateService = new TranslateService();
		},
		mounted() {
			// console.log('组件加载成功');
		},
		methods: {
			//邮箱错误提示
			showError(msg) {
				this.$toast.add({
					severity: 'error',
					summary: '失败',
					detail: msg,
					life: 5000
				});
			},
			//展示成功信息
			showSusscessInfo(msg){
				this.$toast.add({
					severity: 'success',
					summary: '成功',
					detail: msg,
					life: 3000
				});
			},
			//开始处理
			startProcessing(){
				this.$appState.processing=true;
			},
			//停止处理
			stopProcessing(){
				this.$appState.processing=false;
			},
			//用户微信登录
			async userLogin(url,data,login_type){
				this.startProcessing();
				var resp = await this.networkService.postService(url, data);
				if (resp.code == 200) {
					//关掉登录页面
					localStorage.setItem('Authorization', resp.data.cookie);
					localStorage.setItem('Autologin', this.$appState.auto_login);
					if(this.$appState.auto_login==1){
						localStorage.setItem('UserInfo', resp.data.user_info.nickname+'#'+resp.data.user_info.headimgurl);
					}else{
						localStorage.setItem('UserInfo', null);
					}
					this.$appState.new_words_num = resp.data.unsaved_count;
					this.$appState.user_info=resp.data.user_info;
					this.$appState.is_login=true;
					this.$toast.add({
						severity: 'success',
						summary: '登录成功',
						detail: 'Enjoy your study!',
						life: 1000
					});
					//页面跳转到主页
					var _this=this;
					setTimeout(() => {
						//uni.showToast({title: '验证码已发送',icon:"none"});
						_this.$emit('hideLoginContainer');
						_this.$router.push({
							path: '/',
						})
					}, 1000)
					
				} else {
					// this.showError(resp.msg);
					if(login_type==1){
						this.$emit('changeUser');
					}
				}
				this.stopProcessing();
			},
			//查询单词
			async searchWordAndPhrase(search_words,search_from_dic) {
				this.startProcessing();
				var data = new URLSearchParams();
				data.append("search_words", search_words);
				data.append("search_from_dic", search_from_dic);
				data.append("major_name", this.$appState.user_info.major);
				data.append("second_class_name", this.$appState.user_info.major);
				var url = '/paper/search-words';
				// console.log("请求开始");
				var resp = await this.networkService.postService(url, data);
				this.stopProcessing();
				return resp;
			},
			//翻译句子
			async translateSents(sents){
				// console.log('字符长度',sents.length);
				if (this.$appState.ori_lang.code=='en'){
					var model='英译中';
					var max_length=500;
				}else{
					model='中译英';
					max_length=100;
				}
				if(sents.length>max_length){
					var char_length=model+'不支持翻译字符长度超 '+max_length+' 的文本，当前字符长度为 '+sents.length+' ,请删减内容后再次尝试';
					this.showError(char_length);
					resp={
						'data':{
							'translate_text':''
						}
					};
					return resp;
				}
				if (sents==''){
					resp={
						'data':{
							'translate_text':''
						}
					}
					return resp;
				}
				var data = new URLSearchParams();
				
				var ori_lang = this.$appState.ori_lang.code;
				var tag_lang = this.$appState.tag_lang.code;
				
				data.append("ori_lang", ori_lang);
				data.append("tag_lang",tag_lang);
				data.append("second_class_name",  this.$appState.tmp_major);
				
				sents=sents.replace(/<.*?>/ig, "")
				data.append("sents", sents);
				var url = '/translate';
				var resp = await this.translateService.m2mTranslate(url, data);
				// var resp = await this.translateService.m2mTranslate(url, data);
				return resp
			},
			//获取相关文本
			async getText(search_words,text_result_page_num) {
				this.startProcessing();
				var data = new URLSearchParams();
				data.append("search_words", search_words);
				data.append("page_num", text_result_page_num);
				var url = '/paper/search-text';
				var resp={};
				if (this.$appState.select_papers_source.code=='cloud'){
					resp = await this.networkService.postService(url, data);
				}
				else{
					url = '/paper/search-user-text';
					resp = await this.networkService.postService(url, data);
				}
				this.stopProcessing();
				return resp;
			},
			//获取图片
			async getImgs(search_words,img_result_page_num){
				this.startProcessing();
				var data = new URLSearchParams();
				// data.append("search_words", search_words);
				data.append("search_words", search_words);
				data.append("page_num", img_result_page_num);
				var url = '/paper/search-imgs';
				var resp={};
				if (this.$appState.select_papers_source.code=='cloud'){
					resp = await this.networkService.postService(url, data);
				}
				else{
					url = '/paper/search-user-imgs';
					resp = await this.networkService.postService(url, data);
				}
				this.stopProcessing();
				return resp;
			},
			//获取图片解释
			async getExplainOfImgs(item){
				this.startProcessing();
				var data = new URLSearchParams();
				data.append("img_name", item.img_name);
				data.append("pdf_uuid", item.pdf_uuid);
				data.append("pdf_src", item.pdf_src);
				// console.log("请求开始");
				// console.log(search_words);
				var resp={};
				if (this.$appState.select_papers_source.code=='cloud'){
					data.append("journal_name", item.journal_name);
					var url = '/paper/search-imgs-explain';
					resp = await this.networkService.postService(url, data);
				}
				else{
					url = '/paper/search-user-imgs-explain';
					resp = await this.networkService.postService(url, data);
				}
				this.stopProcessing();
				return resp;
			},
			//获取左右页面
			getNeiborPage(pdf_path,delta){
				var pdf_name_page = pdf_path.replace('.pdf', '')
				var page_nums = pdf_name_page.split('_')
				//取最后一个"_"后面的就是页码
				var page_num = page_nums[page_nums.length - 1]
				//页码加减1后合并
				var new_page = parseInt(page_num) + delta;
				page_nums[page_nums.length - 1] = String(new_page)
				
				var new_pdf_path=page_nums.join('_') + '.pdf';
				
				return new_pdf_path;
			},
			//获取指定页面
			getTargetPage(pdf_path,target_page){
				var pdf_name_page = pdf_path.replace('.pdf', '')
				var page_nums = pdf_name_page.split('_')
				//页码加减1后合并
				var new_page = parseInt(target_page)-1;
				page_nums[page_nums.length - 1] = String(new_page)
				var new_pdf_path=page_nums.join('_') + '.pdf';
				
				return new_pdf_path;
			},
			//下载图片
			downloadImg(url) {
				var names=url.split('/');
				var name=names[names.length-1];//取最后的一个，就是图片名称
				
				var that=this;
				let image = new Image()
				image.setAttribute('crossOrigin', 'anonymous')
				image.src = url
				image.onload = () => {
				  let canvas = document.createElement('canvas')
				  canvas.width = image.width
				  canvas.height = image.height
				  let ctx = canvas.getContext('2d')
				  ctx.drawImage(image, 0, 0, image.width, image.height)
				  canvas.toBlob((blob) => {
					let url = URL.createObjectURL(blob)
					that.download(url,name)
					// 用完释放URL对象
					URL.revokeObjectURL(url)
				  })
				}
			  },
			//正式下载图片
			download(href, name) {
			  let eleLink = document.createElement('a')
			  eleLink.download = name
			  eleLink.href = href
			  eleLink.click()
			  eleLink.remove()
			  this.$toast.add({
			  	severity: 'success',
			  	summary: '下载提示',
			  	detail: '图片下载完成',
			  	life: 2000
			  });
			},
			//提示一键复制成功
			showCopySeccessful(){
				this.$toast.add({
					severity: 'success',
					summary: '复制提示',
					detail: '当前段落已复制到剪贴板',
					life: 2000
				});
			},
			//提示文档解析成功
			showPdfParseSuccess() {
				this.$toast.add({
					severity: 'success',
					summary: 'Success Message',
					detail: '文档解析成功',
					life: 3000
				});
			},
			showPdfParseFailed(){
				this.$toast.add({
					severity: 'error',
					summary: 'Error Message',
					detail: '文档解析未完全，翻页时将继续处理',
					life: 5000
				});
			},
			//更新
			showNoSelectWordsFail() {
				this.$toast.add({
					severity: 'error',
					summary: '失败',
					detail: '请先勾选至少一个需操作对象',
					life: 3000
				});
			},
			showUpdateWordsSuccessFully() {
				this.$toast.add({
					severity: 'success',
					summary: '成功',
					detail: '更新选中单词成功',
					life: 3000
				});
			},
			//创建新笔记记录
			async createNewNote(note_name,topic_name){
				this.startProcessing();
				var data = new URLSearchParams();
				data.append("note_name", note_name);
				data.append("topic_name", topic_name);
				var resp={};
				var url = '/note/create-new-note';
				resp = await this.networkService.postService(url, data);
				this.stopProcessing();
				return resp;
			},
			//获取所有笔记记录
			async getAllNote(){
				this.startProcessing();
				var data = new URLSearchParams();
				var resp={};
				var url = '/note/get-all-note';
				resp = await this.networkService.postService(url, data);
				this.stopProcessing();
				return resp;
			},
			//查询所有笔记标题
			async searchNote(search_words){
				this.startProcessing();
				var data = new URLSearchParams();
				var resp={};
				data.append("search_words", search_words);
				var url = '/note/search-note';
				resp = await this.networkService.postService(url, data);
				this.stopProcessing();
				return resp;
			},
			//查询所有笔记内容
			async searchNoteByParas(search_words){
				this.startProcessing();
				var data = new URLSearchParams();
				var resp={};
				data.append("search_words", search_words);
				var url = '/note/search-note-by-paras';
				resp = await this.networkService.postService(url, data);
				this.stopProcessing();
				return resp;
			},
			//获取所有笔记记录
			async deleteNote(note_id){
				this.startProcessing();
				var data = new URLSearchParams();
				var resp={};
				var url = '/note/delete-note';
				data.append("note_id", note_id);
				resp = await this.networkService.postService(url, data);
				this.stopProcessing();
				return resp;
			},
			//获取所有笔记记录
			async uploadNoteHtml(note_id,note_html){
				this.startProcessing();
				var data = new URLSearchParams();
				var resp={};
				var url = '/note/update-note-html';
				data.append("note_id", note_id);
				data.append("note_html", note_html);
				resp = await this.networkService.postService(url, data);
				this.stopProcessing();
				return resp;
			},
			//获取所有笔记记录
			async getNoteHtml(note_id){
				this.startProcessing();
				var data = new URLSearchParams();
				var resp={};
				var url = '/note/get-note-html';
				data.append("note_id", note_id);
				resp = await this.networkService.postService(url, data);
				this.stopProcessing();
				return resp;
			},
			//获取所有笔记记录
			async updateAnnotatiaon(anno_data){
				this.startProcessing();
				var data = new URLSearchParams();
				var resp={};
				var url = '/anno/update-anno';
				data.append("pdf_name", anno_data['pdf_name']);
				data.append("pdf_url", anno_data['pdf_url']);
				data.append("anno_content", JSON.stringify(anno_data['anno_content']));
				resp = await this.networkService.postService(url, data);
				this.stopProcessing();
				return resp;
			},
			//获取所有笔记记录
			async memberCheckIn(){
				this.startProcessing();
				var data = new URLSearchParams();
				var resp={};
				var url = '/member/check-in';
				resp = await this.networkService.postService(url, data);
				this.stopProcessing();
				if (resp.code==200){
					this.showSusscessInfo('签到成功');
				}
				return resp;
			},
			//获取所有笔记记录
			async searchAnnotation(pdf_url){
				this.startProcessing();
				var data = new URLSearchParams();
				var resp={};
				var url = '/anno/search-anno';
				data.append("pdf_url", pdf_url);
				resp = await this.networkService.postService(url, data);
				this.stopProcessing();
				return resp;
			},
			//复制内容到剪切板
			copyToClipboard(ori_text) {
				let text = ori_text.replace(/<.*?>/ig, "");
				this.copyText(text);
				// if (window.clipboardData) {
				// 	window.clipboardData.setData('text', text);
				// } else {
				// 	(function(text) {
				// 		document.oncopy = function(e) {
				// 			e.clipboardData.setData('text', text);
				// 			e.preventDefault();
				// 			document.oncopy = null;
				// 		}
				// 	})(text);
				// 	document.execCommand('Copy');
				// }
			},
			//复制内容到剪切板
			copyHtmlToClipboard(text) {
				this.copyText(text);
				// if (window.clipboardData) {
				// 	window.clipboardData.setData('text', text);
				// } else {
				// 	(function(text) {
				// 		document.oncopy = function(e) {
				// 			e.clipboardData.setData('text', text);
				// 			e.preventDefault();
				// 			document.oncopy = null;
				// 		}
				// 	})(text);
				// 	document.execCommand('Copy');
				// }
				// this.showSusscessInfo('内容已复制到剪贴板');
			},
			copyText (text)  {
			    // 数字没有 .length 不能执行selectText 需要转化成字符串
				// console.log('开始复制',text);
			    var textString = text.toString();
				if (this.$appState.clear_space==true){
					textString=textString.replace(/\s+/g,"");
				}
			    let input = document.querySelector('#copy-input');
			    if (!input) {
			      input = document.createElement('input');
			      input.id = "copy-input";
			      input.readOnly = "readOnly";        // 防止ios聚焦触发键盘事件
			      input.style.position = "absolute";
			      input.style.left = "-2000px";
			      input.style.zIndex = "-2000";
			      document.body.appendChild(input)
			    }
			
			    input.value = textString;
			    // ios必须先选中文字且不支持 input.select();
			    selectText(input, 0, textString.length);
			    // console.log(document.execCommand('copy'), 'execCommand');
			    if (document.execCommand('copy')) {
			      document.execCommand('copy');
			      // alert('已复制到粘贴板');
				  this.showSusscessInfo('内容已复制到剪贴板（如需清除空格可更改“设置-复制时清除空格”）');
			    }
			    input.blur();
			
			    // input自带的select()方法在苹果端无法进行选择，所以需要自己去写一个类似的方法
			    // 选择文本。createTextRange(setSelectionRange)是input方法
			    function selectText(textbox, startIndex, stopIndex) {
			      if (textbox.createTextRange) {//ie
			        const range = textbox.createTextRange();
			        range.collapse(true);
			        range.moveStart('character', startIndex);//起始光标
			        range.moveEnd('character', stopIndex - startIndex);//结束光标
			        range.select();//不兼容苹果
			      } else {//firefox/chrome
			        textbox.setSelectionRange(startIndex, stopIndex);
			        textbox.focus();
			      }
			    }
				
				// 复制文字
				// 必须手动触发 点击事件或者其他事件，不能直接使用js调用！！！
				
				/*兼容性补充：
				 移动端：
				 安卓手机：微信（chrome）和几个手机浏览器都可以用。 
				 苹果手机：微信里面和sarafi浏览器里也都可以，  
				 PC:sarafi版本必须在10.2以上，其他浏览器可以.
				 兼容性测试网站：https://www.caniuse.com/
				*/
			},
		},
	}
</script>

<style scoped lang="scss">
</style>
