<template>
	<!-- 标注多篇文章的主题 -->
	<Dialog :style="{width: '400px'}" header="输入名称" :modal="true">
		<InputText id='new_file_name_id' style='width: 100%;' v-on:keyup.enter="saveNames" v-model='new_file_name' type="text" placeholder="输入名称"></InputText>
		<template #footer>
			<Button label="取消" icon="pi pi-times" class="p-button-text"
				@click="cancelAddName" />
			<Button label="保存" icon="pi pi-check" class="p-button-text" @click="saveNames" />
		</template>
	</Dialog>
</template>

<script>
	import NetWorkService from '../../service/NetWorkService.js';
	export default {
		name: 'newFileName',
		props: {
			new_file_type_copy: {
				default: '输入名称',
				type: String,
			},
			slect_color_copy:{
				
			}
		},
		components: {

		},
		data() {
			return{
				new_file_name:'',
			}
		},
		networkService:null,
		created() {
			this.networkService = new NetWorkService();
		},
		mounted() {
			// console.log('组件加载成功');
			// var new_file_name_id=document.getElementById("new_file_name_id");
			// if(new_file_name_id!=null){
			// 	new_file_name_id.focus();
			// }
		},
		methods: {
			saveNames(){
				this.$emit('saveNames',this.new_file_name);
				this.new_file_name='';
			},
			cancelAddName(){
				this.$emit('hideDialog');
			}
		},
		}
</script>

<style scoped lang="scss">
</style>
