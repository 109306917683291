<template>
	<!-- 	<div :v-if='false'>
		<Login  />
	</div> -->
	<!-- 	<div id='blackSquare'>
		hhhh
	</div> -->
	<Utils ref='Utils'></Utils>
	<input style="left: -2000px;z-index: -2000;position: absolute;" readonly="readonly" id="copy-input" />
	<translateCard ref='translate_card'></translateCard>
	<div v-if='is_processing' style="display: flex;justify-content:center;">
		<div class='card processing-container p-shadow-8' style="top:50;">
			<p style="fontSize: 1.3rem;">操作处理中...<i class="pi pi-spin pi-spinner"
					style="fontSize: 2rem;float: left;margin-right: 30px;"></i><i class="pi pi-times close-processing"
					@click='closeProcessing' title='关闭'></i></p>
		</div>
	</div>
	<div v-if='!is_login'>
		<Login @flushLoginStatus='flushLoginStatus' @goEmailLogin='goEmailLogin' v-if='wechat_login'></Login>
		<EmailLogin @flushLoginStatus='flushLoginStatus' @goWechatLogin='goWechatLogin' v-if='!wechat_login'>
		</EmailLogin>
	</div>

	<div :class="containerClass" @click="onWrapperClick" v-if='is_login'>
		<AppTopBar ref="topBar" @changeNoteWrite='changeNoteWrite' @toggleSetting='toggleSetting'
			@menu-toggle="onMenuToggle" />

		<transition name="layout-sidebar">
			<div :class="sidebarClass" @click="onSidebarClick" v-show="menuVisible">
				<div class="layout-logo">
					<router-link to="/">
						<img style="width: 160px;margin-top: 15px;border-radius: 5px;"
							src="../public/assets/layout/images/firefly-white.png" />
					</router-link>
				</div>

				<AppProfile ref='proFile' @menu-toggle="onMenuToggle" @logOut="logOut" />
				<AppMenu :model="menu" @menuitem-click="onMenuItemClick" />
			</div>
		</transition>
		<div style="display: flex;flex-direction: row;">
			<div :class="'layout-main p-col-12 p-md-'+width_num">
				<router-view v-slot="{ Component }" v-if="$route.meta.keepAlive==true">
					<keep-alive :include="includeList">
						<component @closeNoteEditor="closeNoteEditor" v-bind:is="Component"></component>
					</keep-alive>
				</router-view>
				<router-view v-if="$route.meta.keepAlive==undefined"></router-view>
			</div>
			<div v-if='show_note_editor' v-show='this_show_note_editor' class="layout-main p-col-12 p-md-4">
				<!-- <noteEditor /> -->
				<keep-alive :include="includeList">
					<noteEditor ref='app_note_editor' />
				</keep-alive>
			</div>
		</div>
		<!-- 		<div :class="'layout-main p-col-12 p-md-'+width_num">
			<router-view  v-slot="{ Component }"  v-if="$route.meta.keepAlive==true">
					<keep-alive :include="includeList">
					  <component v-bind:is="Component"></component>
					</keep-alive>
				 </router-view>
			<router-view v-if="$route.meta.keepAlive==undefined"></router-view>
		</div> -->
		<AppConfig :layoutMode="layoutMode" ref='appSetting' :layoutColorMode="layoutColorMode"
			@layout-change="onLayoutChange" @layout-color-change="onLayoutColorChange" />

		<!-- <AppFooter class='p-shadow-4' style='position: fixed;bottom: 0px;width: 100%;margin-top: 200px;' /> -->
		<AppFooter />
	</div>
</template>

<script>
	import AppTopBar from './AppTopbar.vue';
	import AppProfile from './AppProfile.vue';
	import AppMenu from './AppMenu.vue';
	import AppConfig from './AppConfig.vue';
	import AppFooter from './AppFooter.vue';
	import Login from './pages/Login.vue';
	import translateCard from './pages/userComponents/translateCard.vue';
	import EmailLogin from './pages/EmailLogin.vue';
	import Utils from './pages/Utils.vue';
	import noteEditor from './pages/notes/noteEditor.vue';

	import NetWorkService from './service/NetWorkService.js';

	export default {
		// emits: ["closeNoteEditor"],
		data() {
			return {
				trans_text: '翻译文本',
				ori_text: 'translate',
				wechat_login: true,
				is_login: false,
				includeList: ['essayPolishing', 'searchJournalIms', 'readPaper', 'managePapers', 'checkwordlist',
					'noteEditor'
				],
				//登录态,如果是false则跳转到登录页
				menuVisible: false,
				layoutMode: 'overlay',
				layoutColorMode: 'dark',
				staticMenuInactive: false,
				overlayMenuActive: false,
				mobileMenuActive: false,
				menu: [{
						label: '读文献',
						icon: 'pi pi-fw pi-book',
						to: '/'
					},
					{
						label: '查图片',
						icon: 'pi pi-fw pi-search',
						to: '/searchJournalIms'
					},
					{
						label: '查原文',
						icon: 'pi pi-fw pi-list',
						to: '/essayPolishing'
					},
					{
						label: '单词本',
						icon: 'pi pi-fw pi-sort-alpha-up-alt',
						to: '/checkwordlist'
					},
					{
						label: '记笔记',
						icon: 'pi pi-fw pi-pencil',
						to: '/noteStorage'
					},
					{
						label: '文献管理',
						icon: 'pi pi-fw pi-desktop',
						to: '/managePapers'
					},
					{
						label: 'Caj 转 PDF',
						icon: 'pi pi-fw pi-share-alt',
						to: '/docConvert'
					},
					{
						label: '签到领会员',
						icon: 'pi pi-fw pi-check-square',
						to: '/checkIn'
					},
					// {
					// 	label: '本地文献',
					// 	icon: 'pi pi-fw pi-desktop',
					// 	items: [
					// 		{label: '文献管理', icon: 'pi pi-fw pi-folder', to:'/managePapers'},
					// 		{label: '引用分析', icon: 'pi pi-fw pi-link', to: '/keyWordsAnalysis'},
					// 		{label: "关键词分析", icon: "pi pi-fw pi-key", to: "/referenceAnalysis"},
					// 	]
					// },
					// {
					// 	label: 'UI Kit', icon: 'pi pi-fw pi-sitemap',
					// 	items: [
					// 		{label: 'Form Layout', icon: 'pi pi-fw pi-id-card', to: '/formlayout'},
					// 		{label: 'Input', icon: 'pi pi-fw pi-check-square', to: '/input'},
					//                     {label: "Float Label", icon: "pi pi-fw pi-bookmark", to: "/floatlabel"},
					//                     {label: "Invalid State", icon: "pi pi-fw pi-exclamation-circle", to: "invalidstate"},
					// 		{label: 'Button', icon: 'pi pi-fw pi-mobile', to: '/button'},
					// 		{label: 'Table', icon: 'pi pi-fw pi-table', to: '/table'},
					// 		{label: 'List', icon: 'pi pi-fw pi-list', to: '/list'},
					// 		{label: 'Tree', icon: 'pi pi-fw pi-share-alt', to: '/tree'},
					// 		{label: 'Panel', icon: 'pi pi-fw pi-tablet', to: '/panel'},
					// 		{label: 'Overlay', icon: 'pi pi-fw pi-clone', to: '/overlay'},
					// 		{label: 'Menu', icon: 'pi pi-fw pi-bars', to: '/menu'},
					// 		{label: 'Message', icon: 'pi pi-fw pi-comment', to: '/messages'},
					// 		{label: 'File', icon: 'pi pi-fw pi-file', to: '/file'},
					// 		{label: 'Chart', icon: 'pi pi-fw pi-chart-bar', to: '/chart'},
					// 		{label: 'Misc', icon: 'pi pi-fw pi-circle-off', to: '/misc'},
					// 	]
					// },
					// {
					// 	label: "Utilities", icon:'pi pi-fw pi-globe',
					// 	items: [
					// 		{label: 'Display', icon:'pi pi-fw pi-desktop', to:'/display'},
					// 		{label: 'Elevation', icon:'pi pi-fw pi-external-link', to:'/elevation'},
					// 		{label: 'Flexbox', icon:'pi pi-fw pi-directions', to:'/flexbox'},
					// 		{label: 'Icons', icon:'pi pi-fw pi-search', to:'/icons'},
					// 		{label: 'Grid System', icon:'pi pi-fw pi-th-large', to:'/grid'},
					// 		{label: 'Spacing', icon:'pi pi-fw pi-arrow-right', to:'/spacing'},
					// 		{label: 'Typography', icon:'pi pi-fw pi-align-center', to:'/typography'},
					// 		{label: 'Text', icon:'pi pi-fw pi-pencil', to:'/text'},
					// 	]
					// },
					// {
					// 	label: 'Pages', icon: 'pi pi-fw pi-clone',
					// 	items: [
					// 		{label: 'Crud', icon: 'pi pi-fw pi-user-edit', to: '/crud'},
					// 		{label: 'Calendar', icon: 'pi pi-fw pi-calendar-plus', to: '/calendar'},
					// 		{label: 'Timeline', icon: 'pi pi-fw pi-calendar', to: '/timeline'},
					// 		{label: 'Empty Page', icon: 'pi pi-fw pi-circle-off', to: '/empty'}
					// 	]
					// },
				],
			}
		},
		watch: {
			$route() {
				this.menuActive = false;
				this.$toast.removeAllGroups();
			}
		},
		created() {
			this.networkService = new NetWorkService();

		},
		mounted() {
			var that = this;
			//刷新一些默认状态
			that.flushOriginalState();

			window.addEventListener('message', (e) => {
				if (e.data.source != 'html-activate-toast') {
					return;
				}
				this.$refs.Utils.showSusscessInfo(e.data.content);
			});
			//获取拖拽实验对象
			let el = document.getElementById("blackSquare");
			//在该对象上绑定鼠标点击事件
			el.onmousedown = function(e) {
				//鼠标按下，计算鼠标触点距离元素左侧和顶部的距离
				let disX = e.clientX - el.offsetLeft;
				let disY = e.clientY - el.offsetTop;

				//鼠标松开时，注销鼠标事件，停止元素拖拽。
				document.onmouseup = function() {
					document.onmousemove = null;
					document.onmouseup = null;
				};

				document.onmousemove = function(e) {
					//计算需要移动的距离
					let tX = e.clientX - disX;
					let tY = e.clientY - disY;
					//移动当前元素
					if (tX >= 0 && tX <= window.innerWidth - el.offsetWidth) {
						el.style.left = tX + 'px';
					}
					if (tY >= 0 && tY <= window.innerHeight - el.offsetHeight) {
						el.style.top = tY + 'px';
					}
				};
			}
			//鼠标松开时，注销鼠标事件，停止元素拖拽。
			el.onmouseup = function() {
				document.onmousemove = null;
				document.onmouseup = null;
			};
			document.onmousedown = function(ed) {
				let x = '';
				let y = '';
				let _x = '';
				let _y = '';
				x = ed.pageX;
				y = ed.pageY;
				document.onmouseup = function(ep) {
					_x = ep.pageX;
					_y = ep.pageY;
					if (x == _x && y == _y) {
						// console.log('点击事件');
						return; //判断点击和抬起的位置，如果相同，则视为没有滑选，不支持双击选中
					}
					//获取Selection对象
					let selection = window.getSelection();
					var select_text = selection.toString();
					var page_paths = window.location.href.split('/');
					if (page_paths[page_paths.length - 1] != 'essayPolishing' && page_paths[page_paths.length -
							1] != 'searchJournalIms') {
						//如果是读文献页，则不打开单词卡
						return;
					}
					// console.log('当前页面',page_paths);

					if (select_text != '' && that.$appState.select_translate == true) {
						that.$refs.translate_card.translate(select_text);
						that.$appState.show_translate_card = true;
					}
					document.onmousemove = null;
					document.onmouseup = null;
					//调用selection对象的toString()方法就可以获取鼠标拖动选中的文本。
					// console.log("选中的文本为：");
					// console.log(selection.toString());
				}
			};

		},
		methods: {
			closeNoteEditor() {
				console.log('关闭笔记');
				// this.$refs.app_note_editor.saveNoteHtml();
				// this.width_num=12;
				// this.show_note_editor=false;
			},
			changeNoteWrite() {
				//修改笔记打开状态
				if (this.$appState.width_num == 12) {
					var page_paths = window.location.href.split('/');
					if (page_paths[page_paths.length - 1] == 'noteStorage') {
						//如果是笔记历史页，则不打开侧边编辑器
						this.$refs.Utils.showError('请直接笔记看板中点击相应笔记启用编辑');
						return;
					}
					this.$appState.width_num = 8;
					// this.show_note_editor=true;
					this.$appState.show_note_editor = true;
					this.this_show_note_editor = true;
					var _this = this;
					setTimeout(function() {
						_this.$refs.app_note_editor.resizeWin();
					}, 200);
				} else {
					this.$appState.width_num = 12;
					// this.$refs.app_note_editor.saveNoteHtml();
					// this.show_note_editor=false;
					// this.$appState.show_note_editor=false;
					this.this_show_note_editor = false;
				}
			},
			//翻译段落
			goEmailLogin() {
				this.wechat_login = false;
				this.$appState.wechat_login = false;
			},
			goWechatLogin() {
				this.wechat_login = true;
				this.$appState.wechat_login = true;
			},
			flushLoginStatus() {
				this.is_login = this.$appState.is_login;
				this.$router.push({
					path: '/',
				})
			},
			//退出登录，即返回登录页面
			logOut() {
				this.$appState.is_login = false;
				this.is_login = false;
			},
			//刷新用户邮箱
			flushUserEmail() {
				this.$refs.proFile.flushUserEmail();
				this.is_login = true;
			},
			//checkLogin检查登录状态
			checkLogin() {
				// //从页面中获取uid并跳转到登录页
				var url = window.location.href;
				if (url.indexOf('=') != -1) {
					var uids = url.split('=');
					this.uid = uids[uids.length - 1];
				}
				//直接跳转页面
				// if(this.is_login==false){
				// 	this.$router.push(
				// 	{path: '/login'}
				// 	)
				// }
			},
			//打开设置
			toggleSetting() {
				this.$refs.appSetting.toggleConfigurator();
			},
			onWrapperClick() {
				//该函数功能为点击后关闭菜单栏，注释掉之后只能通过点击特定按钮或者正确选择了操作模块之后再关闭
				// if (!this.menuClick) {
				// 	this.overlayMenuActive = false;
				// 	this.mobileMenuActive = false;
				// }

				// this.menuClick = false;
				return;
			},
			onMenuToggle() {
				this.menuClick = true;
				this.menuVisible = !this.menuVisible;
				this.overlayMenuActive = false;
				this.mobileMenuActive = this.menuVisible;
				// if (this.isDesktop()) {
				// 	if (this.layoutMode === 'overlay') {
				// 		if (this.mobileMenuActive === true) {
				// 			this.overlayMenuActive = true;
				// 		}

				// 		this.overlayMenuActive = !this.overlayMenuActive;
				// 		this.mobileMenuActive = false;
				// 	} else if (this.layoutMode === 'static') {
				// 		this.staticMenuInactive = !this.staticMenuInactive;
				// 	}
				// } else {
				// 	this.mobileMenuActive = !this.mobileMenuActive;
				// }

				event.preventDefault();
			},
			onSidebarClick() {
				this.menuClick = true;
			},
			onMenuItemClick(event) {
				// if (event.item && !event.item.items) {
				// 	this.overlayMenuActive = false;
				// 	this.mobileMenuActive = false;
				// }
				if (event.item.items) return;
				this.overlayMenuActive = false;
				this.mobileMenuActive = false;
				this.menuVisible = false;
			},
			onLayoutChange(layoutMode) {
				this.layoutMode = layoutMode;
			},
			onLayoutColorChange(layoutColorMode) {
				this.layoutColorMode = layoutColorMode;
			},
			addClass(element, className) {
				if (element.classList)
					element.classList.add(className);
				else
					element.className += ' ' + className;
			},
			removeClass(element, className) {
				if (element.classList)
					element.classList.remove(className);
				else
					element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') +
						'(\\b|$)', 'gi'), ' ');
			},
			isDesktop() {
				return window.innerWidth > 240;
			},
			isSidebarVisible() {
				if (this.isDesktop()) {
					if (this.layoutMode === 'static')
						return !this.staticMenuInactive;
					else if (this.layoutMode === 'overlay')
						return this.overlayMenuActive;
					else
						return true;
				} else {
					return true;
				}
			},
			flushMajor() {
				this.$refs.appSetting.flushMajor();
			},
			closeProcessing() {
				this.$appState.processing = false;
			},
			//打开时更新状态
			flushOriginalState() {
				if (localStorage.getItem('copy_radio_value') != null) {
					this.$appState.copy_radio_value = localStorage.getItem('copy_radio_value');
				} else {
					this.$appState.copy_radio_value = 'ori_text';
				}
			}
		},
		computed: {
			containerClass() {
				return ['layout-wrapper', {
					'layout-overlay': this.layoutMode === 'overlay',
					'layout-static': this.layoutMode === 'static',
					'layout-static-sidebar-inactive': this.staticMenuInactive && this.layoutMode === 'static',
					'layout-overlay-sidebar-active': this.overlayMenuActive && this.layoutMode === 'overlay',
					'layout-mobile-sidebar-active': this.mobileMenuActive,
					'p-input-filled': this.$appState.inputStyle === 'filled',
					'p-ripple-disabled': this.$primevue.config.ripple === false
				}];
			},
			sidebarClass() {
				return ['layout-sidebar', {
					'layout-sidebar-dark': this.layoutColorMode === 'dark',
					'layout-sidebar-light': this.layoutColorMode === 'light'
				}];
			},
			logo() {
				return (this.layoutColorMode === 'dark') ? "assets/layout/images/logo-white.svg" :
					"assets/layout/images/logo.svg";
			},
			is_processing() {
				return this.$appState.processing;
			},
			show_note_editor() {
				return this.$appState.show_note_editor;
			},
			width_num() {
				return this.$appState.width_num;
			}
		},
		beforeUpdate() {
			if (this.mobileMenuActive)
				this.addClass(document.body, 'body-overflow-hidden');
			else
				this.removeClass(document.body, 'body-overflow-hidden');
		},
		components: {
			'AppTopBar': AppTopBar,
			'AppProfile': AppProfile,
			'AppMenu': AppMenu,
			'AppConfig': AppConfig,
			'AppFooter': AppFooter,
			'Login': Login,
			'EmailLogin': EmailLogin,
			'translateCard': translateCard,
			'noteEditor': noteEditor,
			'Utils': Utils,
		}
	}
</script>

<style lang="scss">
	@import './App.scss';

	.close-processing {
		fontSize: 0.5rem;
		float: right;
		margin-left: 20px;
		border-radius: 50%;
		// padding: 3px;
		// background-color: red;
	}

	.processing-container {
		z-index: 1000000;
		position: absolute;
		top: 80px;
		opacity: 0.95;
		padding: 10px;
		width: 240px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		// box-shadow:  0px 0px 7px #888888;
	}
</style>
