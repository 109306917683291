<template>
	<div class="middle" :style="backgroundDiv">
		<div class="login-container">
			<img src="../../public/assets/layout/images/logo-dark.png" style="opacity: 0.7;width: 220px;justify-content: center;" alt="" />
			<div class="p-inputgroup" style="margin-top: 10px;">
				<InputText id="user_email" :value='email' :disabled='!show'  v-on:keyup.enter="getVerifyCode"  type="text"
					placeholder="邮箱" />
				<!-- <Button icon='pi pi-chevron-down' /> -->
			</div>
			<div class="p-inputgroup" style="margin-top: 20px;">
				<InputText type="text" :disabled='!show'  :value='vecode' v-on:keyup.enter="login" id='vericode' style="width: 160px;" placeholder="验证码" />
				<Button style="width: 60px;" v-show='show' @click='getVerifyCode' label="获取"></Button>
				<Button style="width: 60px;" class="p-button-secondary" v-show='!show' disabled :label="vecode_time+' S'"></Button>
			</div>
			<Button  @click='login' label="邮箱登录" title='需要先绑定邮箱后才可使用邮箱登录' style="margin-top: 20px;"></Button>
			<div style="display: flex;flex-direction: row;justify-content: space-between;">
<!-- 				<Button label="*需先绑定邮箱" style="margin-top: 15px;" class="p-button-secondary p-button-text"
					:disabled='false' /> -->
				<div class="p-field-checkbox" title='勾选之后此设备将可在两周免验证登录,请勿在非本人设备使用!' style="justify-content: center;transform: scale(0.8,0.8);margin-top: 5px;margin-bottom: -10px;">
					<Checkbox style='border-radius: 50%;' id="checkOption1" name="option" value="此设备自动登录" @change="changeAutoLogin" v-model="checkboxValue" />
					<label for="checkOption1" style="color: #666666;">允许此设备自动登录</label>
				</div>
				<Button label="微信登录" style="margin-top: 15px;" class="p-button-secondary p-button-text"
					@click='goWechatLogin' />
			</div>
		</div>
	</div>
</template>

<script>
	import NetWorkService from '../service/NetWorkService.js';
	export default {
		emits: ['goWechatLogin','flushLoginStatus'],
		data() {
			return {
				checkboxValue:[],
				vecode_time:0,
				has_cookie:false,
				visibleFull: true,
				show: true,
				count: '',
				email:null,
				vecode:'',
				timer: null,
				backgroundDiv: {
					backgroundImage: 'url(https://fireflycos.libertynlp.com/firefly-static/login_backimg.jpg)',
				}
			}
		},
		created() {
			this.networkService = new NetWorkService();
		},
		mounted(){
			this.$appState.auto_login=0;
		},
		methods: {
			changeAutoLogin(){
				this.$appState.auto_login=this.checkboxValue.length;
				// console.log('当前登录',this.checkboxValue.length);
			},
			changeEmail(){
				this.has_cookie=false;
				document.getElementById('user_email').value='';
			},
			//倒计时
			setTimer() {
				let holdTime = 19,
					_this = this;
				_this.vecode_time = "19"
				_this.Timer = setInterval(() => {
					if (holdTime <= 0) {
						_this.vecode_time = "20"
						clearInterval(_this.Timer);
						_this.show=true;
						return;
					}
					_this.vecode_time =holdTime;
					holdTime--;
				}, 1000)
			},
			//用验证码和邮箱登录
			login(){
				var vecode=document.getElementById('vericode').value;
				if (vecode.length!=6 || vecode.replace(/[^0-9]/ig,"").length!=6){
					this.showEmainError('请输入六位数字验证码');
					return;
				}
				//再次验证邮箱是否正确
				var user_email=document.getElementById('user_email').value;
				this.tryEmailLogin(user_email,vecode);
			},
			async tryEmailLogin(user_email,veri_code) {
				this.processing = true;
				this.$appState.processing=true;
				var data = new URLSearchParams();
				data.append("user_email",user_email);
				data.append("veri_code",veri_code);
				var url = '/member/email-login';
				// console.log("请求开始");
				var resp = await this.networkService.postService(url, data);
				if(resp.code==200){
					//关掉登录页面
					localStorage.setItem('Authorization', resp.data.cookie);
					localStorage.setItem('Autologin', this.$appState.auto_login);
					if(this.$appState.auto_login==1){
						localStorage.setItem('UserInfo', resp.data.user_info.nickname+'#'+resp.data.user_info.headimgurl);
					}else{
						localStorage.setItem('UserInfo', null);
					}

					this.$appState.new_words_num = resp.data.unsaved_count;
					this.$appState.user_info=resp.data.user_info;
					
					this.$appState.is_login=true;
					this.$emit('flushLoginStatus');
					this.$toast.add({
						severity: 'success',
						summary: '登录成功',
						detail: 'Enjoy your study!',
						life: 1000
					});
				}else{
					this.show=true;
					this.showEmainError(resp.msg);
				}
				this.processing = false;
				this.$appState.processing=false;
			},
			//邮箱错误提示
			showEmainError(msg){
				this.$toast.add({
					severity: 'error',
					summary: '失败',
					detail: msg,
					life: 4000
				});
			},
			//从云端获取验证码
			getVerifyCode(){
				//打开计时器
				this.show = false;
				
				var user_email=document.getElementById('user_email').value;
				this.email=user_email;
				this.getVeriCode(user_email);
			},
			//获取验证码
			async getVeriCode(user_email) {
				this.$appState.processing=true;
				var data = new URLSearchParams();
				data.append("user_email",user_email);
				var url = '/member/get-vericode';
				// console.log("请求开始");
				var resp = await this.networkService.postService(url, data);
				this.show=false;
				if(resp.code==200){
					this.$toast.add({
						severity: 'success',
						summary: '成功',
						detail: resp.msg,
						life: 3000
					})
					var _this=this;
					setTimeout(() => {
						//uni.showToast({title: '验证码已发送',icon:"none"});
						_this.setTimer();
					}, 1000)
				}else{
					this.show=true;
					this.showEmainError(resp.msg);
				}
				this.$appState.processing=false;
			},
			goWechatLogin() {
				this.$emit('goWechatLogin');
			},

		}

	}
</script>

<style scoped>
	.middle {
		height: 100%;
		position: fixed;
		width: 100%;
		background: no-repeat center top;
		background-size: cover;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.login-container {
		display: flex;
		width: 280px;
		flex-direction: column;
		padding: 20px;
		border-radius: 10px;
		/* background-color:#55585a+7a; */
		background-color: rgba(255, 255, 255, 0.4);
		justify-content: center;
	}

	.login-lable {
		color: #000000;
		opacity: 0.6;
		font-weight: 700;
	}

	.input-container {
		display: flex;
		flex-direction: column;
		margin-top: 20px;
	}
</style>
