<template>
	<Utils ref='Utils' />
	<div class="layout-topbar">
		<button class="p-link layout-menu-button" @click="onMenuToggle" title="菜单栏">
			<span class="pi pi-bars"></span>
		</button>

		<div class="layout-topbar-icons">
<!-- 			<span class="layout-topbar-search">
				<InputText type="text" width="800px" placeholder="Search" />
				<span class="layout-topbar-search-icon pi pi-search"></span>
			</span> -->
			<button class="p-link" title="打开翻译查询卡" @click="showTranslateCard">
				<span class="layout-topbar-item-text">查单词</span>
				<span class="layout-topbar-icon pi pi-search"></span>
			</button>
			<button class="p-link" title="刷新当前页面" @click="refreshPage">
				<span class="layout-topbar-item-text">刷新</span>
				<span class="layout-topbar-icon pi pi-refresh"></span>
			</button>
			<button class="p-link" @click="checkWordList" title="单词管理">
				<span class="layout-topbar-item-text">Events</span>
				<span class="layout-topbar-icon pi pi-calendar"><i class="p-mr-1 p-text-secondary"
						v-if="new_words_num>0" style="font-size: 1.5rem;color: #ffffff;"
						v-badge.danger="new_words_num"></i></span>

			</button>
			<button class="p-link" @click="toggleSetting($event)" title="翻译与文献来源设置">
				<span class="layout-topbar-item-text">Settings</span>
				<span class="layout-topbar-icon pi pi-cog">
					<i class="p-mr-1 p-text-secondary"
							style="font-size: 1.5rem;color: #ffffff;"
							v-badge.danger=""></i>
				</span>
			</button>
<!-- 			<button class="p-link" @click="goAccount" title="账户管理">
				<span class="layout-topbar-item-text">User</span>
				<span class="layout-topbar-icon pi pi-user"></span>
			</button> -->
			<button class="p-link" @click="changeNoteWrite" title="打开/关闭笔记">
				<span class="layout-topbar-item-text">Note</span>
				<span class="layout-topbar-icon pi pi-fw pi-pencil">
					<i class="p-mr-1 p-text-secondary"
							style="font-size: 1.5rem;color: #ffffff;"
							v-badge.danger="'New'"></i>
				</span>
			</button>
		</div>
	</div>
</template>

<script>
	import Utils from './pages/Utils.vue';
	export default {
		data() {
			return {
				
			}
		},
		mounted() {
			
		},
		components: {
			'Utils': Utils,
		},
		methods: {
			changeNoteWrite(){
				this.$emit('changeNoteWrite');
			},
			showTranslateCard(){
				this.$appState.show_translate_card=true;
			},
			onMenuToggle(event) {
				this.$emit('menu-toggle', event);
			},
			toggleSetting() {
				// console.log(event);
				this.$emit('toggleSetting');
			},
			checkWordList() {
				this.$router.push({
					path: '/checkwordlist',
				})
			},
			goAccount(){
				this.$router.push({
					path: '/userAccount',
				})
			},
			refreshPage(){
				// console.log('当前路径',window.location.href);
				var page_paths=window.location.href.split('/');
				// var path='/'+page_paths[page_paths.length-1];
				var path=page_paths[page_paths.length-1];
				if (path==''){
					path='/'
				}
				// console.log(page_paths);
				// console.log('页面跳转',path);
				this.$router.push({
					name: 'transPage',
					params: {
					  target_path: path, 
					}
				})
				// window.location.reload();
			}
		},
		computed:{
			new_words_num(){
				var unsaved_count = this.$appState.new_words_num;
				
				if (unsaved_count > 99) {
					return '99+';
				} else {
					return unsaved_count;
				}
			}
		}
	}
</script>
