<template>
	<Toast />
	<Utils ref='appUtils'></Utils>
	<EditWord  @reflushProduts="reflushProduts" @initialProduct='initialProduct' ref='editWord' :product_copy="product" :showDialog='productDialog' :disabled_or='true' v-if='productDialog' />
	<div v-show='show_translate_card' class='card translate-card p-shadow-4' id='blackSquare'>
		<div class='card' style="margin-top: 5px;margin-bottom: -5px;">
			<i class="pi pi-times close-processing" @click='closeTranslateCard' title='关闭' style="margin-top: 3px;"></i>
			<Button label="翻译段落" @click="searchSents" title='翻译当前原文'></Button>
			<Button icon="pi pi-search" label="查单词" style="margin-left: 20px;" title='以当前原文为关键词查单词' @click="searchWord"></Button>
		</div>
		<div class='card'>
			<Divider layout="horizontal" align="center">
				<span class="p-tag" @click="copyLink('trans_ori_text')">原文
					<i class="pi pi-book" style="float: right;margin-left: 1em;margin-top: 2px;" title='复制'></i>
				</span>
			</Divider>
			<i class="pi pi-times initial-ori-text" @click.stop="InitialWords" title='清空输入框'></i>
			<p contenteditable="true" @focusin="focusIn" v-on:keyup.enter="searchSents" id="trans_ori_text">{{trans_ori_text}}</p>
		</div>
		<div class='card-gray' v-show='word_trans'>
			<div class='card' style="margin-bottom:10px;">
				<div class="translation-item" style="font-weight: 700;padding-bottom: 5px;background-color:#f8f9fa;">
					<div class='p-col-4 title-item'>原文</div>
					<div class='p-col-4 title-item'>释义<i class="pi pi-pencil" @click="editWord" title="编辑"
							style="color: var(--primary-color);"></i></div>
					<div class='p-col-4 title-item'>来源<i v-if='from_personal' class="pi pi-refresh" title="重新查询"
							@click="searchFromDics" style="color: var(--primary-color);"></i></div>
				</div>
				<div class="word-item translation-item" style="border-top:1px solid #ededed;padding: 2px 0px 2px 0px;"
					v-for="(item, j) of products" :key="j">
					<div class='p-col-4'>{{word.or}}</div>
					<div class='p-col-4'>{{item.w}}</div>
					<div v-if='item.mjid_from.length==1' class='p-col-4'>{{item.mjid_from[0].fr}}</div>
					<div v-if='item.mjid_from.length>1' class='p-col-4' style="padding: 0;">
						<Dropdown v-model="item.sl_mjid_from" :options="item.mjid_from" optionLabel="fr"
							placeholder="来源" />
					</div>
				</div>
			</div>

			<div class='card' style="margin-bottom:0px;">
				<div class="translation-item" style="font-weight: 700;padding-bottom: 5px;background-color:#f8f9fa;">
					<div class='p-col-4'>相似</div>
					<div class='p-col-4'>释义</div>
					<div class='p-col-4'>来源</div>
				</div>
				<div class='card-gray' style="margin-bottom:0px;">
					<div class="word-item translation-item"
						style="border-top:1px solid #ededed;padding: 2px 0px 2px 0px;"
						v-for="(item, j) of similar_words" :key="j">
						<div class='p-col-4' @click="searchWordItem(item)">{{item.w}}</div>
						<div class='p-col-4'>{{item.tr}}</div>
						<div v-if='item.mjid_from.length==1' class='p-col-4'>{{item.mjid_from[0].fr}}</div>
						<div v-if='item.mjid_from.length>1' class='p-col-4' style="padding: 0;">
							<Dropdown v-model="item.sl_mjid_from" :options="item.mjid_from" optionLabel="fr"
								placeholder="来源" />
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class='card' v-show='!word_trans'>
			<Divider layout="horizontal" align="center">
				<span class="p-tag" @click="copyLink('trans_tag_text')">译文
					<i class="pi pi-book" style="float: right;margin-left: 1em;margin-top: 2px;" title='复制'></i>
				</span>
			</Divider>
			<p contenteditable="true" @focusin="focusIn" id="trans_tag_text" :v-if='!word_trans'>{{trans_tag_text}}</p>
		</div>
	</div>
</template>

<script>
	import NetWorkService from '../../service/NetWorkService.js';
	import Utils from '../Utils.vue';
	import EditWord from '../userComponents/EditWord.vue';
	export default {
		emits:['InitialWords'],
		name: '',
		props: {
			para_copy: {
				default: null,
				type: Object
			}
		},
		components: {
			'Utils': Utils,
			'EditWord': EditWord,
		},
		computed: {
			show_translate_card() {
				return this.$appState.show_translate_card;
			}
		},
		data() {
			return {
				word:null,
				productDialog:false,
				product:null,
				
				similar_words:[],
				show_word_list: false,
				products:[],
				
				from_personal:false, //是否是从用户表查询的结果
				search_from_dic:false,
				
				word_trans: false,
				trans_tag_text: '翻译',
				trans_ori_text: 'translate',
				para: this.para_copy, //此后para可在methods中操作
			}
		},
		networkService: null,
		created() {
			this.networkService = new NetWorkService();
		},
		mounted() {

		},
		methods: {
			//从字典表中查询单词
			searchFromDics(){
				this.search_from_dic=true;
				this.searchWord();
			},
			//查询类似单词
			searchWordItem(item) {
				this.select_word=item.w;
				this.translateWordsByLocal(item.w);
			},
			//初始化单词编辑
			initialProduct(){
				this.product = null;
				this.productDialog = false;
				// console.log('当前单词已重置');
			},
			//单词编辑
			editWord() {
				if(this.word){
					this.product = {
						...this.word
					};
					this.productDialog = true;
				}
			},
			InitialWords() {
				document.getElementById("trans_ori_text").value = '';
				this.trans_ori_text='';
				this.initialResult();
			},
			initialResult(){
				if (this.word_trans == false){
					document.getElementById("trans_tag_text").value = '';
					this.trans_tag_text='';
				}else{
					this.word=null;
					this.productDialog=false;
					this.product=null;
					
					this.similar_words=[];
					this.show_word_list=false;
					this.products=[];
					
					this.from_personal=false; //是否是从用户表查询的结果
					this.search_from_dic=false;
				}
			},
			searchWord() {
				let word = document.getElementById("trans_ori_text").innerText;
				this.word_trans = true;
				this.translateWordsByLocal(word);
				this.initialResult();
			},
			focusIn() {
				document.onmousemove = null;
				document.onmouseup = null;
			},
			closeTranslateCard() {
				this.$appState.show_translate_card = false;
			},
			searchSents() {
				let sentents = document.getElementById("trans_ori_text").innerText;
				this.word_trans = false;
				this.translate(sentents);
			},
			//本地词库查询
			async translateWordsByLocal(content) {
				// console.log("请求开始");
				this.$appState.processing=true;
				this.word_trans=true;
				var resp = await this.$refs.appUtils.searchWordAndPhrase(content,this.search_from_dic);
				this.products = resp.data.words.tl;
				this.word = resp.data.words;
				this.from_personal=resp.data.from_personal;
				this.similar_words=resp.data.similar_words;
				this.search_from_dic=false;
				//更新数量
				this.$appState.new_words_num = resp.data.unsaved_count;
				this.$appState.processing=false;
			},
			async translate(sentents) {
				this.$appState.processing = true;
				this.word_trans=false;
				this.initialResult();
				this.trans_ori_text = sentents;
				var resp = await this.$refs.appUtils.translateSents(sentents);
				this.trans_tag_text = resp.data.translate_text;
				this.$appState.processing = false;
			},
			//一键复制文本
			copyLink(explain_id) {
				// var explain_id='img_explain_'+j;
				var explain = document.getElementById(explain_id);
				window.getSelection().selectAllChildren(explain);
				document.execCommand("Copy");
				this.$toast.add({
					severity: 'success',
					summary: '复制提示',
					detail: '当前段落已复制到剪贴板',
					life: 2000
				});
			},
		},
	}
</script>

<style scoped lang="scss">
	.translate-card {
		z-index: 100000;
		width: 350px;
		// position: fixed;
		position: absolute;
		top:50px;
		height: 400px;
		overflow-y: auto;
	}

	.close-processing {
		fontSize: 0.5rem;
		float: right;
		margin-left: 20px;
		border-radius: 50%;
		padding: 3px;
		// background-color: red;
		background-color: var(--primary-color);
		color:#ffffff;
		margin-top: 10px;
		margin-right: 10px;
	}
	
	.title-item{
		display: flex; 
		align-items: center;
		justify-content: space-between;
	}
	
	.initial-ori-text{
		margin-left: 10px;
		padding: 3px;
		border-radius: 50%;
		// background-color: var(--primary-color);
		// color:#ffffff;
		float: right;
		margin-right: 10px;
	}
</style>
