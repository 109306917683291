<template>
	<Utils ref='Utils' @hideLoginContainer='hideLoginContainer' @changeUser='changeUser' />
	<div class="middle" :style="backgroundDiv">
		<!-- 		<transition-group style="margin: 20px;" name="p-messages" tag="div">
			<Message :severity="'error'"><span style="font-weight: 800;">通告：萤火虫服务器遭到攻击，目前正在努力恢复中，预计9月29日00:00后恢复，可能会有部分数据丢失</span>
			</Message>
			<Message :severity="'error'"><span style="font-weight: 800;">我只是想做一个应用而已，居然会被这样对待，低估了垃圾人的丑恶！</span>
			</Message>
		</transition-group>
		<Button type="button" style="margin-top: 0.625rem;"  icon='pi pi-thumbs-up' title='不会就这样放弃这个应用，希望大家可以支持我！' label="支持我"
			@click="toggle" class="p-button-warning p-mr-2" />
		<OverlayPanel ref="op" appendTo="body" :showCloseIcon="true">
			<img src="../../public/assets/layout/images/charge.jpg" style='width: 350px;' alt="charge" />
		</OverlayPanel>
		<a style="margin-top: 20px;" href="https://mp.weixin.qq.com/s/ICp6zZrpepCw2AJJJwcy5w"  target="_blank">
			<Button title='关注公众号应用恢复时快捷登录'
				icon="pi pi-external-link" style="width: 100%;margin-bottom: 20px;font-weight: 800;" label="关注公众号"
				class="p-button-help mb-3" />
		</a> -->
		<!-- 		<div style="display: flex;align-items: center;margin-bottom: 20px;z-index:10;width: 100%;justify-content: center;">
			<ins class="adsbygoogle" style="display:inline-block;width:728px;height:90px;"
				data-ad-client="ca-pub-8147879111198627" data-ad-slot="5292546828"></ins>
		</div> -->
		<div>
			<a href="http://dict.libertynlp.com" target="_blank">
				<Button title='专业双语词汇,可查单词,可买词典(免登录)' icon="pi pi-external-link"
					style="width: 100%;margin-bottom: 20px;font-weight: 800;" label="萤火虫专业双语词典"
					class="p-button-help mb-3" />
			</a>
		</div>
<!-- 		<Button title='萤火虫遭受攻击及恢复情况' icon="pi pi-external-link"
			style="margin-bottom: 20px;font-weight: 800;" label="9月27日服务器遭受攻击简报"
			class="p-button-danger mb-3" @click="showAttackInfo" /> -->
		<div class="login-container" align="center">
			<img src="../../public/assets/layout/images/logo-dark.png"
				style="opacity: 0.7;width: 220px;margin-left: 5px;" alt="" />
			<div v-if='!has_cookie'>
				<img v-if='qr_code_avaiable' src="../../public/assets/layout/images/wechatlogin.png"
					style="width: 150px;margin-top: 15px;border-radius: 5px;" alt="" />
				<img v-if='qr_code_avaiable' :src="wechat_login_qrcode"
					style="width: 180px;border-radius: 10px;margin-top: 10px;" alt="" />
				<Button v-if='qr_code_avaiable' :label="'二维码将于'+qr_avaiable_time+'秒后失效'"
					class="p-button-secondary p-button-text" />
				<Button v-if='!qr_code_avaiable' @click='getWechatLoginQrcode' label="二维码已失效,点我刷新"
					style="margin-top: 20px;"></Button>
				<div
					style="display: flex;flex-direction: row;justify-content: space-between;margin-top: 10px;margin-bottom: -10px;align-items: center;">
					<div class="p-field-checkbox" title='勾选之后此设备将可在两周免验证登录,请勿在非本人设备使用!'
						style="justify-content: center;transform: scale(0.8,0.8);">
						<Checkbox style='border-radius: 50%;' id="checkOption1" name="option" value="此设备自动登录"
							@change="changeAutoLogin" v-model="checkboxValue" />
						<label for="checkOption1" style="color: #666666;">允许此设备自动登录</label>
					</div>
					<Button label="邮箱登录" style="margin-top: -15px;" class="p-button-secondary p-button-text"
						@click='goEmailLoginPage' />
				</div>
			</div>
			<div v-if='has_cookie'>
				<img :src="user_headimgurl" style="width: 100px;margin-top: 25px;border-radius: 5px;" alt="" />
				<h3 style="font-weight: 600;margin-top: 10px;">{{user_nickname}}</h3>
				<Button @click='checkLogin' :disabled='processing' label="登录当前账号"
					style="width: 200px;margin-top: 10px;"></Button>
				<div style="display: flex;flex-direction: row;justify-content: space-between;">
					<Button label="切换账号" style="margin-top: 15px;" class="p-button-secondary p-button-text"
						@click='changeUser' />
					<Button label="邮箱登录" style="margin-top: 15px;" class="p-button-secondary p-button-text"
						@click='goEmailLoginPage' />
				</div>

			</div>
			<!-- 						<div>
							<Button label="获取空闲二维码" @click='getWechatLoginQrcode' class="p-mr-2 p-mb-2"/>
							<Button label="模拟微信回调" @click='trWeChatCallBack' class="p-mr-2 p-mb-2"/>
							<Button label="查询扫描状态" @click='tryWeChatLogin' class="p-mr-2 p-mb-2"/>
						</div> -->

		</div>
	</div>
</template>

<script>
	import NetWorkService from '../service/NetWorkService.js';
	import Utils from './Utils.vue';
	export default {
		emits: ['goEmailLogin', 'flushLoginStatus'],
		data() {
			return {
				adsenseContent: '',
				checkboxValue: [],
				qr_code_ut: 0,
				qr_avaiable_time: "60",
				qr_code_avaiable: false,
				time_count: 0,
				wechat_login_qrcode: '',
				uuid: 0,

				invote_user_id: '', //邀请者的id
				has_cookie: false,
				visibleFull: true,
				show: true,
				count: '',
				email: null,
				vecode: '',
				timer: null,
				backgroundDiv: {
					backgroundImage: 'url(https://fireflycos.libertynlp.com/firefly-static/login_backimg.jpg)',
				}
			}
		},
		created() {
			this.networkService = new NetWorkService();
		},
		components: {
			'Utils': Utils,
		},
		mounted() {
			//调试时忽略登录
			// this.skipLogin();
			// this.changeUser();
			// //调试时自动登录
			// this.checkLogin();
			this.checkCookie();
			// window.addAds();
			// console.log(document.getElementById('divadsensedisplaynone').innerHTML);
			// document.getElementById('adsgoeshere').innerHTML=document.getElementById('divadsensedisplaynone').innerHtml;
			// this.getWechatLoginQrcode();
			// this.getUid();
		},
		methods: {
			// //获取扫码登录的二维码
			// async tryWeChatCallBack() {
			// 	var data = new URLSearchParams();
			// 	var url = '/member/wx-call-back';
			// 	var resp = await this.networkService.postService(url, data);
			// 	console.log(resp)
			// },
			showAttackInfo(){
				var info="2022年9月27日 22:47 ，萤火虫服务器数据库遭到入侵，所有后台数据被清空，而后导致服务崩溃\n\n"+
				"经过一天的努力，恢复了2022年8月4日之前的数据，因此登录账户后可能会发现缺少了部分笔记和论文数据\n\n"+
				"很抱歉给大家造成了损失，现在漏洞已经被修复了，欢迎继续使用莹火虫！后续将持续给大家带来好用的功能，国庆期间将会上线【批量caj转pdf】的桌面端应用，敬请期待！";
				alert(info)
			},
			toggle(event) {
				this.$refs.op.toggle(event);
			},
			changeAutoLogin() {
				this.$appState.auto_login = this.checkboxValue.length;
				// console.log('当前登录',this.checkboxValue.length);
			},
			//倒计时
			setTimer() {
				let holdTime = 59,
					_this = this;
				_this.qr_avaiable_time = "59"
				_this.Timer = setInterval(() => {
					if (holdTime <= 0) {
						_this.qr_avaiable_time = "60"
						clearInterval(_this.Timer);
						return;
					}
					_this.qr_avaiable_time = holdTime;
					holdTime--;
				}, 1000)
			},
			//切换账号
			changeUser() {
				this.has_cookie = false;
				this.$appState.auto_login = 0;
				this.getWechatLoginQrcode();
			},
			//轮询扫码状态
			checkScanStatus() {
				var that = this;
				var t2 = window.setInterval(function() {
					that.time_count += 1;

					//比对当前时间和二维码请求的时间，如果时间差超过二维码有效期，请求时设置的是60秒，考虑响应不及时这里设置为55秒，否则可能比二维码后过期
					var timeInMs = Date.now() / 1000;
					var scene_time = parseFloat(that.qr_code_ut)
					if (timeInMs - scene_time >= 59) {
						that.qr_code_avaiable = false;
						window.clearInterval(t2); // 去除定时器
					}

					//轮询 15 次或者已登录则清除计时操作
					if (that.time_count >= 15 || that.visibleFull == false || that.$appState.is_login == true ||
						that.$appState.wechat_login == false) {
						window.clearInterval(t2); // 去除定时器
					} else {
						that.tryWeChatLogin()
					}
				}, 5000)
			},
			//尝试微信登录
			tryWeChatLogin() {
				var data = new URLSearchParams();
				data.append("uuid", this.uuid);
				// var scence_id='0fcf83ef0c885a5e93487ee65e7dcb91#1632537479.8398948';
				// data.append("scene_id", scence_id);

				data.append("invote_user_id", this.invote_user_id);
				var url = '/member/login';
				this.$refs.Utils.userLogin(url, data, 0);
			},
			//获取扫码登录的二维码
			async getWechatLoginQrcode() {
				this.$appState.processing = true;
				var data = new URLSearchParams();
				var url = '/member/get-wechat-qrcode';
				var resp = await this.networkService.postService(url, data);
				if (resp.code == 200) {
					this.wechat_login_qrcode = resp.data.url;
					this.uuid = resp.data.uuid;
					this.qr_code_ut = resp.data.ut;
					this.qr_code_avaiable = true;
					this.time_count = 0;
					this.checkScanStatus();
					var _this = this;
					setTimeout(() => {
						//uni.showToast({title: '验证码已发送',icon:"none"});
						_this.setTimer();
					}, 1000)
				}
				this.$appState.processing = false;
			},
			//调试时忽略登录
			skipLogin() {
				//页面跳转到主页
				this.$router.push({
					path: '/checkIn',
				})
			},
			//检查验证码
			checkCookie() {
				// //获取url中的uid
				// console.log(uid);
				this.invote_user_id = this.$route.params.uid;
				if (localStorage.getItem('Autologin') != 0) {
					this.$appState.auto_login = localStorage.getItem('Autologin');
				}
				// console.log('auto_login',this.$appState.auto_login);
				if (this.$appState.auto_login == 0) {
					this.changeUser();
					return;
				}
				// console.log('长度',localStorage.getItem('UserInfo'));
				// localStorage.setItem('UserInfo',null);
				if (localStorage.getItem('UserInfo') != null && localStorage.getItem('UserInfo').split('#').length == 2) {
					this.has_cookie = true;
					this.$appState.auto_login = 1;
					this.$appState.user_info.nickname = localStorage.getItem('UserInfo').split('#')[0];
					this.$appState.user_info.headimgurl = localStorage.getItem('UserInfo').split('#')[1];
				} else {
					this.changeUser();
				}
			},
			//自动登录检验登录态
			checkLogin() {
				//cookie存在之后再检查登录态
				var data = new URLSearchParams();
				var url = '/member/check-login-token';
				// var url = '/member/test-money';
				this.$refs.Utils.userLogin(url, data, 1);
			},
			//关闭登录
			hideLoginContainer() {
				this.$emit('flushLoginStatus');
			},
			goEmailLoginPage() {
				this.$emit('goEmailLogin');
			}
		},
		computed: {
			user_headimgurl() {
				return this.$appState.user_info.headimgurl;
			},
			user_nickname() {
				return this.$appState.user_info.nickname;
			},
			processing() {
				return this.$appState.processing;
			}
		}

	}
</script>

<style scoped>
	.middle {
		height: 100%;
		position: fixed;
		width: 100%;
		background: no-repeat center top;
		background-size: cover;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.login-container {
		display: flex;
		width: 280px;
		flex-direction: column;
		padding: 20px;
		border-radius: 10px;
		/* background-color:#55585a+7a; */
		background-color: rgba(255, 255, 255, 0.4);
		justify-content: center;
		content: normal;
	}

	.login-lable {
		color: #000000;
		opacity: 0.6;
		font-weight: 700;
	}

	.input-container {
		display: flex;
		flex-direction: column;
		margin-top: 20px;
	}
</style>
