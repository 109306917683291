<template>
	<!-- 标注多篇文章的主题 -->
	<Dialog :style="{width: '400px'}" header="选择颜色" :modal="true">
		<div class="p-grid" style="overflow-x: hidden;">
<!-- 			<div class="padding-sm p-col-12 p-lg-6">
				<div class="padding-sm" v-for="(item,index) in ColorList" :key="index">
					<div @click="saveColor(index,0,item)" title='选择后编辑器背景和字体会变成相应颜色' class="padding radius text-center shadow-blur" :class="'bg-' + item.name">
						<div class="text-lg">{{item.title}}</div>
						<div class="margin-top-sm text-Abc">{{item.name}}</div>
					</div>
				</div>
			</div> -->
			<div class="p-col-12 bg-white padding-sm">
<!-- 				<div title='开启后无论选择下方何种样式背景都会是白色' style="margin-top: 40px;">
					<h6>白色背景</h6>
					<InputSwitch v-model="white_bg" @change="changeWhiteBg" />
				</div> -->
				<div class="padding-sm" v-for="(item,index) in ColorList" :key="index">
					<div @click="saveColor(index)" title='选择后编辑器背景和字体会变成相应颜色' class="padding radius text-center light" :class="'bg-' + item.name">
						<div class="text-lg">{{item.title}}</div>
						<div class="margin-top-sm text-Abc">{{item.name}}</div>
					</div>
				</div>
			</div>
		</div>
<!-- 		<template #footer>
			<Button label="取消" icon="pi pi-times" class="p-button-text" @click="cancelAddName" />
			<Button label="保存" icon="pi pi-check" class="p-button-text" @click="saveNames" />
		</template> -->
	</Dialog>
</template>

<script>
	// import NetWorkService from '../../service/NetWorkService.js';
	export default {
		name: 'newFileName',
		props: {},
		components: {

		},
		data() {
			return {
				white_bg:false,
				ColorList: [{
						title: '嫣红',
						name: 'red',
						color: '#e54d42',
						code: '#fadbd9',
					},
					{
						title: '桔橙',
						name: 'orange',
						color: '#f37b1d',
						code: '#fde6d2',
					},
					{
						title: '明黄',
						name: 'yellow',
						color: '#333333',
						code: '#fef2ce',
					},
					{
						title: '橄榄',
						name: 'olive',
						color: '#8dc63f',
						code: '#e8f4d9',
					},
					{
						title: '森绿',
						name: 'green',
						color: '#39b54a',
						code: '#d7f0dbff',
					},
					{
						title: '天青',
						name: 'cyan',
						color: '#1cbbb4',
						code: '#d2f1f0',
					},
					{
						title: '海蓝',
						name: 'blue',
						color: '#0081ff',
						code: '#cce6ff',
					},
					{
						title: '姹紫',
						name: 'purple',
						color: '#6739b6',
						code: '#e1d7f0',
					},
					{
						title: '木槿',
						name: 'mauve',
						color: '#9c26b0',
						code: '#ebd4ef',
					},
					{
						title: '桃粉',
						name: 'pink',
						color: '#e03997',
						code: '#f9d7ea',
					},
					{
						title: '棕褐',
						name: 'brown',
						color: '#a5673f',
						code: '#ede1d9',
					},
					{
						title: '玄灰',
						name: 'grey',
						color: '#8799a3',
						code: '#e7ebed',
					},
					{
						title: '草灰',
						name: 'gray',
						color: '#aaaaaa',
						code: '#e7ebed',
					},
					{
						title: '墨黑',
						name: 'black',
						color: '#333333',
						code: '#ffffff',
					},
					{
						title: '雅白',
						name: 'white',
						color: '#ffffff',
						code: '#333333',
					},
				],
				dropdownValue: null,
			}
		},
		networkService: null,
		created() {
			// this.networkService = new NetWorkService();
		},
		mounted() {
			// console.log('组件加载成功');
		},
		methods: {
			changeWhiteBg(){
				if (this.white_bg==true){
					localStorage.setItem('white_bg', '1');
				}else{
					localStorage.setItem('white_bg', '0');
				}
			},
			saveColor(index){
				var item=this.ColorList[index];
				var theme_name='';
				var button_theme='light ';
				var bq_value=item.code;
				if (index<12){
					theme_name='light ';
					button_theme='';
					bq_value=item.color;
				}
				theme_name=theme_name+'bg-'+item.name;
				button_theme=button_theme+'bg-'+item.name;
				var change_item={
					'theme_name':theme_name,
					'color_value':item.color,
					'button_theme':button_theme,
					'bq_value':bq_value,
					'name':item.name,
				}
				localStorage.setItem('theme_id',index);
				this.$emit('saveColor',change_item);
			},
		},
	}
</script>

<style scoped lang="scss">
	.text-center {
		text-align: center;
	}
	
	.margin-top-sm {
		margin-top: 0.625rem;
	}

	.text-Abc {
		text-transform: Capitalize;
	}

	.text-lg {
		font-size: 1.25rem;
	}

	.padding {
		padding:0.625rem;
	}
	
	.padding-sm {
		padding: 0.625rem;
	}

	.radius {
		border-radius: 0.375rem;
	}


	.bg-red {
		background-color: #e54d42;
		color: #ffffff;
	}

	.bg-orange {
		background-color: #f37b1d;
		color: #ffffff;
	}

	.bg-yellow {
		background-color: #fbbd08;
		color: #333333;
	}

	.bg-olive {
		background-color: #8dc63f;
		color: #ffffff;
	}

	.bg-green {
		background-color: #39b54a;
		color: #ffffff;
	}

	.bg-cyan {
		background-color: #1cbbb4;
		color: #ffffff;
	}

	.bg-blue {
		background-color: #0081ff;
		color: #ffffff;
	}

	.bg-purple {
		background-color: #6739b6;
		color: #ffffff;
	}

	.bg-mauve {
		background-color: #9c26b0;
		color: #ffffff;
	}

	.bg-pink {
		background-color: #e03997;
		color: #ffffff;
	}

	.bg-brown {
		background-color: #a5673f;
		color: #ffffff;
	}

	.bg-grey {
		background-color: #8799a3;
		color: #ffffff;
	}

	.bg-gray {
		background-color: #f0f0f0;
		color: #333333;
	}

	.bg-black {
		background-color: #333333;
		color: #ffffff;
	}

	.bg-white {
		background-color: #ffffff;
		color: #666666;
	}

	.bg-shadeTop {
		background-image: linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.01));
		color: #ffffff;
	}

	.bg-shadeBottom {
		background-image: linear-gradient(rgba(0, 0, 0, 0.01), rgba(0, 0, 0, 1));
		color: #ffffff;
	}

	.bg-red.light {
		color: #e54d42;
		background-color: #fadbd9;
	}

	.bg-orange.light {
		color: #f37b1d;
		background-color: #fde6d2;
	}

	.bg-yellow.light {
		color: #333333;
		background-color: #fef2ced2;
	}

	.bg-olive.light {
		color: #8dc63f;
		background-color: #e8f4d9;
	}

	.bg-green.light {
		color: #39b54a;
		background-color: #d7f0dbff;
	}

	.bg-cyan.light {
		color: #1cbbb4;
		background-color: #d2f1f0;
	}

	.bg-blue.light {
		color: #0081ff;
		background-color: #cce6ff;
	}

	.bg-purple.light {
		color: #6739b6;
		background-color: #e1d7f0;
	}

	.bg-mauve.light {
		color: #9c26b0;
		background-color: #ebd4ef;
	}

	.bg-pink.light {
		color: #e03997;
		background-color: #f9d7ea;
	}

	.bg-brown.light {
		color: #a5673f;
		background-color: #ede1d9;
	}

	.bg-grey.light {
		color: #8799a3;
		background-color: #e7ebed;
	}

	.bg-gradual-red {
		background-image: linear-gradient(45deg, #f43f3b, #ec008c);
		color: #ffffff;
	}

	.bg-gradual-orange {
		background-image: linear-gradient(45deg, #ff9700, #ed1c24);
		color: #ffffff;
	}

	.bg-gradual-green {
		background-image: linear-gradient(45deg, #39b54a, #8dc63f);
		color: #ffffff;
	}

	.bg-gradual-purple {
		background-image: linear-gradient(45deg, #9000ff, #5e00ff);
		color: #ffffff;
	}

	.bg-gradual-pink {
		background-image: linear-gradient(45deg, #ec008c, #6739b6);
		color: #ffffff;
	}

	.bg-gradual-blue {
		background-image: linear-gradient(45deg, #0081ff, #1cbbb4);
		color: #ffffff;
	}

	.bg-default.light {
		color: #000000;
		background-color: #fff9e7;
	}
</style>
