<template>
	<div class="layout-footer" style="display: flex;flex-wrap: wrap;justify-content:space-between;">
		<div style="display: flex;align-items: center;">
			<a target='_blank' href='https://cn.libertynlp.com' title="访问官网" style="color: #000000;">
				<img src="../public/assets/layout/images/logo-dark.png" alt="firefly" width="100" />
				<span class="footer-text"
					title="We can only see a short distance ahead, but we can see plenty there that needs to be done. Alan M. Turing"
					style="margin-left: 25px">短浅目光所及之处，仍有许多事必须去做。 <span style="opacity: 0.5;">阿兰 M·图灵</span></span>
			</a>
		</div>
		<!-- 		<div style="display: flex;align-items: center;">
			<ins class="adsbygoogle" style="display:inline-block;width:728px;height:90px;width:728px;height:90px;"
				data-ad-client="ca-pub-8147879111198627" data-ad-slot="5292546828"></ins>
		</div> -->
		<!-- <span class="footer-text" style="margin-right: 5px">OpenCS</span> -->
		<!-- <a title="点击查看软件操作视频教程" class='video-address' href="https://www.bilibili.com/video/BV1aL411u77m/" target="_blank">软件操作视频教程</a> -->
<!-- 		<div>
			<a href="http://dict.libertynlp.com"  target="_blank">
				<span title='专业双语词汇,可查单词,可买词典(免登录)' class="p-tag" style="margin-right: 10px;">萤火虫专业双语词汇</span>
			</a>
			<a title="前往浏览LibertyNLP博客" href="https://cn.libertynlp.com/index.php/bolgs/" target="_blank">
				<span class="p-tag" style="margin-right: 10px;">LibertyNLP博客</span>
			</a>
			<Dropdown @update:modelValue="videoValue" style='padding: 0px;margin: 0px;border: none;font-weight: 600;'
				:options="videoValues" optionLabel="name" placeholder="软件操作视频教程" />
		</div> -->
		<div>
			<Button type="button" icon='pi pi-thumbs-up'
				title='在校研究生自费运维的项目，希望大家赞赏支持，共同维护项目长久' label="赞赏支持" @click="toggle" class="p-button-warning p-mr-2" />
			<OverlayPanel ref="op" appendTo="body" :showCloseIcon="true">
				<img src="assets/layout/images/charge.jpg" style='width: 350px;' alt="charge" />
			</OverlayPanel>
		</div>
	</div>
</template>

<script>
	export default {
		name: "AppFooter",
		data() {
			return {
				videoValues: [{
						name: '6-在线笔记使用【新】',
						code: '7',
						url: 'https://www.zhihu.com/zvideo/1484150128554991616',
					},
					{
						name: '0-萤火虫使用视频教程',
						code: '1',
						url: 'https://www.zhihu.com/zvideo/1444079995878166528',
					},
					{
						name: '1-英文论文全文翻译',
						code: '2',
						url: 'https://www.zhihu.com/zvideo/1445497449905758209',
					},
					{
						name: '2-专业学术词汇查记用',
						code: '3',
						url: 'https://www.zhihu.com/zvideo/1446900900720160768',
					},
					{
						name: '3-图片查询寻找作图灵感',
						code: '4',
						url: 'https://www.zhihu.com/zvideo/1448068035852943360',
					},
					{
						name: '4-全文检索辅助论文润色',
						code: '5',
						url: 'https://www.zhihu.com/zvideo/1448379867960549376',
					},
					{
						name: '5-划词翻译查询单词',
						code: '6',
						url: 'https://www.zhihu.com/zvideo/1448789209175302144',
					}
				],
			}
		},
		mounted() {
			// window.addAds();
		},
		methods: {
			videoValue(value) {
				window.open(value.url, "_blank");
			},
			toggle(event) {
				this.$refs.op.toggle(event);
			},
		}
	}
</script>

<style scoped>
	.video-address {
		color: #000000;
	}

	.video-address:hover {
		color: #1fa1fc;
	}
</style>
