<template>
	<div class='card' id='note_editor_container' style="height: 100%;margin-top: -7px;padding-bottom: 0px;">
		<div class="card-gray">
			<Menubar :model="menuNoteItems">
			</Menubar>
		</div>
		<!-- //搜索框-->
		<div :height='note_height' v-if='show_search_container' class='card-gray'>
			<div class='card' style="margin-bottom: 0;">
				<div style="display:flex;flex: wrap;justify-content:space-between;">
					<div class="p-inputgroup" id="search_note_hsi_box">
						<span class="p-input-icon-right">
							<InputText autocomplete="off" style="width:100%;" v-model="this_search_his_words"
								v-on:keyup.enter="searchNotesThisPage" type="text" placeholder="混合查询笔记内容" />
							<i class="pi pi-times" style="opacity: 0.7;" @click.stop="InitialWordsThisPage"
								title='清空输入框' />
						</span>
						<span class="p-inputgroup-addon" @click="searchNotesThisPage">
							<i class="pi pi-search" title='查询'></i>
						</span>
					</div>
					<Button icon="pi pi-times" class="p-button-danger" @click="show_search_container=false" title="关闭搜索框" />
				</div>
				<!-- //显示搜索结果 -->
				<div class='topic_his_container' v-if='this_notes_info.length>0'>
					<div class="word-item" title='点击进入笔记编辑' style="display: flex;flex-direction: column;border-top:1px solid #ededed;"
						@mouseover="showSearchResult(j)" @mouseleave="closeSearchResult(j)" v-for="(item, j) of this_notes_info" :key="j">
						<div class='item-container' @click="addHtmlToEditor(item.id,item.note_name,'')">
							<div class='p-col-12 title-item item-container'>
								<i style="margin-right: 10px;opacity: 0.5;"><b>{{j+1}}</b></i>
								<p class="word-item" v-html="item.note_name"></p>
								<p v-if='item.num>0'><span style='color:#0081ff;font-weight:bold;'>@ {{item.num}}
										条记录</span></p>
							</div>
							<!-- <div class='p-col-1' @click.stop="deleteThisNote(j)" title='删除笔记' style="display: flex;align-items: center;justify-content: center;color: #1fa1fc;"><i class="pi pi-trash"></i></div> -->
<!-- 							<div class='p-col-1' title='编辑笔记'
								style="display: flex;align-items: center;justify-content: center;color: #1fa1fc;"><i
									class="pi pi-pencil"></i></div> -->
						</div>
						<div class='card-gray p-col-12' style="display: flex;flex-direction: column;margin-bottom: 0px;"
							v-if='item.num>0 && item.show_result'>
							<div class='result-item' @click="addHtmlToEditor(item.id,item.note_name,para)" v-for="(para,i) of item.paras" :key="i" style="display: flex;flex-direction: column;">
								<p style='padding: 5px;' v-html="para"></p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class='card-gray'>
			<iframe ref='notes_editor' id='note_container' src="medium-editor/custom-toolbar.html" frameborder="0"
				width="100%" :height='note_height'></iframe>
		</div>
		<!--上传文件的提示-->
		<uploadFile ref='upload_file' v-model:visible="show_add_paper_dialog" :upload_url_copy='upload_url'>
		</uploadFile>
		<newFileName ref='new_file_name' :new_file_type_copy='new_file_type' @hideDialog='hideDialog'
			@saveNames='saveNames' v-model:visible='show_new_file_name'></newFileName>
		<selectBackColor ref='select_theme' @hideDialog='hideDialog' @saveColor='saveColor'
			v-model:visible='show_color_picker'></selectBackColor>
		<Utils ref='Utils' />
		<div v-if="screenshotStatus" style="position: fixed;top:0;left:0;z-index:100000000;width: 102%;height: 100%;">
			<iframe ref='screenShot' src="medium-editor/testScreenShot.html" frameborder="0"
				style="width: 100%;height:100%;"></iframe>
		</div>
		<!-- 删除所选单个项目的提示 -->
		<Dialog v-model:visible="deleteProductDialog" :style="{width: '450px'}" header="提示" :modal="true">
			<div class="confirmation-content">
				<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem;" />
				<span>删除笔记后将无法恢复，仍然确定要删除<b> {{current_edit_note_name}} </b>?</span>
			</div>
			<template #footer>
				<Button label="否" icon="pi pi-times" class="p-button-text" @click="deleteProductDialog = false" />
				<Button label="是" icon="pi pi-check" class="p-button-text" @click="deleteCurrentNote" />
			</template>
		</Dialog>
	</div>
</template>
<script>
	// import NetWorkService from '../../service/NetWorkService.js';
	import uploadFile from '../userComponents/uploadFile.vue';
	import newFileName from '../userComponents/newFileName.vue';
	import selectBackColor from '../userComponents/selectBackColor.vue';
	import Utils from '../Utils.vue';
	export default {
		emits: ['hideLoginContainer', 'changeUser', 'changeNoteWrite', 'toggleSetting', 'menuToggle'],
		name: '',
		props: {
			para_copy: {
				default: null,
				type: Object
			}
		},
		components: {
			'uploadFile': uploadFile,
			'newFileName': newFileName,
			'Utils': Utils,
			'selectBackColor': selectBackColor,
		},
		data() {
			return {
				screenshotStatus: false,
				show_new_file_name: false,
				show_color_picker: false,
				deleteProductDialog: false,
				current_edit_id: '',
				current_edit_note_name: '',
				search_words: '',
				new_file_type: '',
				topic_name: '',
				upload_url: "/api/upload/image",
				show_add_paper_dialog: false,
				note_height: '600px',
				this_search_his_words: '',
				this_notes_info: [],
				this_tmp_notes_info: [],
				show_search_container: false,
				menuNoteItems: [{
						label: '笔记',
						items: [{
							label: '新建专题',
							icon: 'pi pi-fw pi-plus',
							command: () => {
								this.addNewFile('topic', 'topic');
							},
						}]
					},
					{
						label: '导出',
						items: [{
								label: 'PDF',
								icon: 'pi pi-fw pi-file-pdf',
								command: () => {
									this.printPDF()
								},
							},
							{
								label: 'HTML',
								icon: 'pi pi-fw pi-globe',
								command: () => {
									this.downloadHtml()
								},
							},
							// {
							// 	label: 'MarkDown',
							// 	icon: 'pi pi-fw pi-caret-down',
							// 	command: () => {
							// 		this.convertHtml2Md()
							// 	},
							// },
							{
								label: 'Word',
								icon: 'pi pi-fw pi-file',
								command: () => {
									this.convertHtml2Doc()
								},
							},
						]
					},
					{
						label: '操作',
						items: [{
								label: '保存Ctrl+S',
								icon: 'pi pi-fw pi-cloud-upload',
								command: () => {
									this.saveNoteHtml();
								},
							},
							{
								label: '删除',
								icon: 'pi pi-fw pi-trash',
								command: () => {
									this.deleteConfirm(this.current_edit_id, this.current_edit_note_name);
								},
							},
							{
								label: '导入并合并',
								icon: 'pi pi-fw pi-inbox',
								command: () => {
									this.importNoteHtml();
								},
							},
							{
								label: '颜色配置',
								icon: 'pi pi-fw pi-palette',
								command: () => {
									this.show_color_picker = true;
								},
							},
							{
								label: '打开另起一行',
								icon: 'pi pi-fw pi-eye',
								command: () => {
									this.openCreateNewPTag();
								},
							},
							{
								label: '关闭另起一行',
								icon: 'pi pi-fw pi-eye-slash',
								command: () => {
									this.closeCreateNewPTag();
								},
							},
							{
								label: '跳转笔记顶部',
								icon: 'pi pi-fw pi-angle-up',
								command: () => {
									this.goNoteTopOrDown('top');
								},
							},
							{
								label: '跳转笔记底部',
								icon: 'pi pi-fw pi-angle-down',
								command: () => {
									this.goNoteTopOrDown('down');
								},
							}
						]
					},
					{
						label: '搜索',
						icon: 'pi pi-fw pi-search',
						command: () => {
							this.showSearchContainer();
						},
					},
				],
			}
		},
		networkService: null,
		created() {
			// this.networkService = new NetWorkService();
		},
		beforeRouterLeave(to, from, next) {
			to.meta.keepAlive = true;
			next()
		},
		mounted() {
			window.addEventListener('message', (e) => {
				var this_data = e.data;
				if (this_data.source != 'mediumediotr' && this_data.source != 'uploadnotesimg' && this_data.source != 'note-html') {
					return
				}
				
				if (this_data.source=='note-html'){
					this.$refs.Utils.copyHtmlToClipboard(this_data.content);
					return;
				}
	
				
				if (this_data.source == 'mediumediotr') {
					this.screenshotStatus = false;
					if (this_data.content == '打开文件上传模块') {
						this.show_add_paper_dialog = true;
						return;
					} else if (this_data.content == '打开截屏模块') {
						this.screenshotStatus = true;
						return;
					} else if (this_data.content == '加载主题颜色') {
						this.readEditorTheme();
						return;
					} else if (this_data.content == '关闭截屏模块') {
						this.screenshotStatus = false;
						if (this_data.code == -1) {
							this.$refs.Utils.showError('浏览器不支持webrtc截屏或用户取消了授权，终止截屏');
						}
						return;
					} else if (this_data.content === '保存笔记到云端') {
						this.saveNoteHtml();
					} else {
						this.show_add_paper_dialog = true;
						var _this = this;
						setTimeout(function() {
							_this.$refs.upload_file.uploadPasteImg(this_data.content);
						}, 2000);
					}
				} else {
					this.show_add_paper_dialog = false;
					this.$refs.notes_editor.contentWindow.insertImg(this_data.content.url);
				}
			});
			this.getPersonNotes();
			this.resizeWin();
		},
		methods: {
			readEditorTheme() {
				var theme_id = 0;
				if (localStorage.getItem('theme_id') != null) {
					theme_id = parseInt(localStorage.getItem('theme_id'));
				} else {
					theme_id = 2;
				}
				try {
					this.$refs.select_theme.saveColor(theme_id);
				} catch {
					console.log('欢迎使用笔记');
				}
			},
			toggleMenu(event) {
				this.$refs.menu.toggle(event);
			},
			fakeClick(obj) {
				var ev = document.createEvent("MouseEvents");
				ev.initMouseEvent(
					"click", true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null
				);
				obj.dispatchEvent(ev);
			},
			exportRawHtml(name, data) {
				var urlObject = window.URL || window.webkitURL || window;

				var export_blob = new Blob([data]);

				var save_link = document.createElementNS("http://www.w3.org/1999/xhtml", "a")
				save_link.href = urlObject.createObjectURL(export_blob);
				save_link.download = name;
				this.fakeClick(save_link);
			},
			downloadHtml() {
				var note_container = this.getContainerHtml();
				var file_name = document.getElementById('note_container').contentWindow.document.getElementById(
					'file_title').innerHTML + '.html';
				this.exportRawHtml(file_name, note_container);
			},
			goNoteTopOrDown(op) {
				if (op == 'top') {
					document.getElementById('note_container').contentWindow.document.getElementById('goTopA').click();
				} else {
					document.getElementById('note_container').contentWindow.document.getElementById('goBottomA').click();
				}
			},
			getContainerHtml() {
				var note_container = document.getElementById('note_container').contentWindow.document.getElementById(
					'container').innerHTML;
				this.current_edit_id = document.getElementById('note_container').contentWindow.document.getElementById(
					'note_id_input').innerHTML;
				return note_container;
			},
			printPDF() {
				document.getElementById('note_container').contentWindow.print();
			},
			async saveNames(new_name) {
				if (this.new_file_type == 'topic') {
					var new_topic = this.createNewTopic(new_name);
					this.menuNoteItems[0].items.push(new_topic);
				} else {
					var topic_index = this.findIndexByTopicName();
					var resp = await this.$refs.Utils.createNewNote(new_name, this.topic_name);
					//更新当前ID,加入编辑页面
					this.$refs.notes_editor.contentWindow.isertContainerHtml(resp['note_id'], new_name, resp[
						'note_html']);
					this.current_edit_id = resp['note_id'];
					this.current_edit_note_name = new_name;
					var new_file = this.createNewNote(new_name, resp['note_id']);
					this.menuNoteItems[0].items[topic_index].items.push(new_file);
				}
				this.hideDialog();
				this.$refs.Utils.showSusscessInfo('新建成功');

				var page_paths = window.location.href.split('/');
				if (page_paths[page_paths.length - 1] == 'noteStorage') {
					//如果是笔记历史页，则刷新历史
					this.$emit('flushNotesHis', this.menuNoteItems[0].items);
				}
			},
			//结构化新文件
			createNewNote(new_name, note_id) {
				var new_file = {
					label: new_name,
					icon: 'pi pi-fw pi-copy',
					note_id: note_id,
					show_result: false,
					command: () => {
						//输入ID
						this.addHtmlToEditor(note_id, new_name,'');
					},
				};
				return new_file;
			},
			//结构化新文件
			createNewNoteWithParas(new_name, note_id, paras, paras_num) {
				var new_file = {
					label: new_name,
					paras_list: paras,
					paras_num: paras_num,
					show_result: false,
					icon: 'pi pi-fw pi-copy',
					note_id: note_id,
					command: () => {
						//输入ID
						this.addHtmlToEditor(note_id, new_name,'');
					},
				};
				return new_file;
			},
			findIndexByTopicName() {
				let index = -1;
				for (let i = 0; i <= this.menuNoteItems[0].items.length; i++) {
					if (this.menuNoteItems[0].items[i].label === this.topic_name) {
						index = i;
						break;
					}
				}
				return index;
			},
			hideDialog() {
				this.show_new_file_name = false;
				this.show_color_picker = false;
			},
			addNewFile(file_type, topic_name) {
				this.new_file_type = file_type;
				this.show_new_file_name = true;
				this.topic_name = topic_name;
			},
			createNewTopic(new_topic_name) {
				var new_topic = {
					label: new_topic_name,
					icon: 'pi pi-fw pi-folder-open',
					items: [{
						label: '新建笔记',
						icon: 'pi pi-fw pi-plus',
						command: () => {
							this.addNewFile('note', new_topic_name);
						},
					}, ]
				};
				return new_topic;
			},
			async addHtmlToEditor(note_id, note_name,para) {
				this.current_edit_id = note_id;
				this.current_edit_note_name = note_name;
				var resp = await this.$refs.Utils.getNoteHtml(note_id);
				this.$refs.notes_editor.contentWindow.isertContainerHtml(note_id, note_name, resp['note_html']);
				if(para!=''){
					this.$refs.notes_editor.contentWindow.findKeyWords(para.replace(/<.*?>/ig, ""));
				}
				this.resizeWin();
			},
			openCreateNewPTag() {
				this.$refs.notes_editor.contentWindow.openCreateNewPTag();
			},
			closeCreateNewPTag() {
				this.$refs.notes_editor.contentWindow.closeCreateNewPTag();
			},
			importNoteHtml(atach) {
				this.$refs.notes_editor.contentWindow.importNoteHtml(atach);
			},
			async getPersonNotes() {
				var resp = await this.$refs.Utils.getAllNote();
				var notes_info = resp.notes_info;
				this.search_words = '';
				this.createMenuNotesItems(notes_info);

				//如果是笔记历史页，则刷新历史
				var page_paths = window.location.href.split('/');
				if (page_paths[page_paths.length - 1] == 'noteStorage') {
					this.$emit('flushNotesHis', this.menuNoteItems[0].items);
				}
			},
			//查询笔记的标题
			async searchPersonNotes(search_words) {
				this.search_words = search_words;
				var resp = await this.$refs.Utils.searchNote(search_words);
				var notes_info = resp.notes_info;
				this.createMenuNotesItems(notes_info);

				//如果是笔记历史页，则刷新历史
				var page_paths = window.location.href.split('/');
				if (page_paths[page_paths.length - 1] == 'noteStorage') {
					this.$emit('flushNotesHis', this.menuNoteItems[0].items);
				}
			},
			//按照笔记内容混合查询笔记
			async searchPersonNotesByParas(search_words) {
				this.search_words = search_words;
				var resp = await this.$refs.Utils.searchNoteByParas(search_words);
				var notes_info = resp.notes_info;
				this.createMenuNotesItems(notes_info);

				//如果是笔记历史页，则刷新历史
				var page_paths = window.location.href.split('/');
				if (page_paths[page_paths.length - 1] == 'noteStorage') {
					this.$emit('flushNotesHis', this.menuNoteItems[0].items);
				}
			},
			deleteConfirm(note_id, note_name) {
				this.current_edit_note_name = note_name;
				this.current_edit_id = note_id;
				this.deleteProductDialog = true;
			},
			//删除当前笔记
			async deleteCurrentNote() {
				this.deleteProductDialog = false;
				//如果删除的是当前的笔记的话，则把笔记改成默认的
				var this_delete_id = document.getElementById('note_container').contentWindow.document.getElementById(
					'note_id_input').innerHTML;
				if (this_delete_id == this.current_edit_id) {
					// console.log('替换成默认的html');
					this.$refs.notes_editor.contentWindow.loadUsageHtml();
				}
				var resp = await this.$refs.Utils.deleteNote(this.current_edit_id);
				if (resp.code == 200) {
					this.$refs.Utils.showSusscessInfo(resp.msg);
					var _this = this;
					setTimeout(function() {
						_this.getPersonNotes();
					}, 2000);
				}
			},
			createMenuNotesItems(notes_info) {
				var topics = [];
				var menu_notes = [];
				for (let i = 0; i < notes_info.length; i++) {
					var note = notes_info[i];
					var this_topic = note['topic'];
					var topic_index = 0;
					if (topics.indexOf(this_topic) == -1) {
						topic_index = topics.length;
						topics.push(this_topic);

						//新建主题
						var new_topic = this.createNewTopic(this_topic);
						menu_notes.push(new_topic);
					} else {
						topic_index = topics.indexOf(this_topic);
					}

					//新建笔记
					if (this.search_words == '') {
						var new_note = this.createNewNoteWithParas(note['note_name'], note['id'], [], 0);
					} else {
						new_note = this.createNewNoteWithParas(note['note_name'], note['id'], note['paras'], note['num']);
					}
					var this_topic_notes = menu_notes[topic_index];
					this_topic_notes.items.push(new_note);

					menu_notes[topic_index] = this_topic_notes;
				}
				// console.log(menu_notes);
				menu_notes.unshift({
					label: '新建专题',
					icon: 'pi pi-fw pi-plus',
					command: () => {
						this.addNewFile('topic', 'topic');
					},
				});
				this.menuNoteItems[0].items = menu_notes;
			},
			//上传html
			async saveNoteHtml() {
				var note_html = this.getContainerHtml();
				// console.log('note_id:',this.current_edit_id,';note_html',note_html);
				if (this.current_edit_id == 'no_note') {
					this.$refs.Utils.showError('当前笔记为示例笔记，无法保存');
					return;
				}
				var resp = await this.$refs.Utils.uploadNoteHtml(this.current_edit_id, note_html);
				this.$refs.Utils.showSusscessInfo(resp.msg);
			},
			//屏幕变化调整样式
			resizeWin() {
				// var note_editor_container = document.getElementById('note_editor_container');
				// this.note_height = note_editor_container.offsetHeight - 200 + 'px';
				var windowHeight = document.documentElement.clientHeight;
				this.note_height = windowHeight - 210 + 'px';

				// console.log('调整大小',this.note_height);
			},
			saveColor(item) {
				this.$refs.notes_editor.contentWindow.changeThemeColor(item);
				// this.show_color_picker=false;
			},
			convertHtml2Md() {
				var note_editor_container = document.getElementById('note_editor_container');
				this.$refs.notes_editor.contentWindow.writeHtml2Markdown(note_editor_container);
			},
			convertHtml2Doc() {
				this.$refs.notes_editor.contentWindow.writeHtml2Doc();
			},
			//当前页面发起搜索
			async searchNotesThisPage() {
				if (this.this_search_his_words == '') {
					this.$refs.Utils.showError('请输入关键后发起搜索');
					return;
				}
				var search_words = this.this_search_his_words;
				var resp = await this.$refs.Utils.searchNoteByParas(search_words);
				var notes_info = resp.notes_info;
				this.this_notes_info = notes_info;
				if (this.this_notes_info.length==0){
					this.$refs.Utils.showSusscessInfo('笔记搜索完成，没有找到符合条件的笔记');
				}
				// console.log(this.this_notes_info)
				// this.search_words=search_words;
			},
			InitialWordsThisPage() {
				this.this_notes_info = [];
				this.this_search_his_words = '';
			},
			deleteThisNote(j) {
				var item = this.this_notes_info[j];
				this.deleteConfirm(item.id, item.note_name);
				// var tmp_nots_info=this.this_notes_info;
				// this.this_tmp_notes_info=tmp_nots_info.splice(j,j+1);
			},
			showSearchResult(j) {
				this.this_notes_info[j].show_result = true;
			},
			closeSearchResult(j) {
				this.this_notes_info[j].show_result = false;
			},
			showSearchContainer() {
				this.show_search_container = !this.show_search_container;
				this.this_notes_info=[];
				this.this_search_his_words='';
			},
			closeSearchContainer() {
				this.show_search_container = false;
				this.this_notes_info=[];
				this.this_search_his_words='';
				this.resizeWin();
			}
		},
	}
</script>

<style scoped lang="scss">
	.word-item {
		display: flex;
		flex-direction: row;
		padding: 2px 0px 2px 0px;
		margin: 0 auto 0 0;
	}

	.title-item:hover {
		// background-color: #1fa1fc;
		// color: #1fa1fc;
		color:#0081ff;
		// font-weight:bold;
	}
	
	.result-item:hover{
		background-color: #CCCCCC;
		// font-weight:bold;
	}

	.topic_his_container {
		max-height: 220px;
		overflow-y: auto;
	}

	.item-container {
		display: flex;
		flex-direction: row;
		align-items: center;
	}
</style>
