<template>
	<div class="layout-profile">
		<div style="display: flex;flex-direction: column; align-items: center;justify-content: center;">
			<img :src="user_headimgurl" style="border-radius: 5px;width: 5rem;" alt="" />
			<button title='打开个人中心' class="p-link layout-profile-link" style="font-size: 1rem;display: flex;align-items: center;justify-content: center;" @click="onClick">
				<i class="pi pi-fw pi-user"></i>
				<span class="username" style="margin-left: 1rem;">{{user_nickname}}</span>
			</button>
		</div>

        <transition name="layout-submenu-wrapper">
            <ul v-show="expanded" @click="onMenuToggle">
                <li @click="UserAccount"><button class="p-link"><i class="pi pi-fw pi-user"></i><span>账户</span></button></li>
                <li @click="logOut"><button class="p-link"><i class="pi pi-fw pi-power-off"></i><span>退出登录</span></button></li>
				<li @click="getHelp"><button class="p-link"><i class="pi pi-fw pi-question-circle"></i><span>帮助反馈</span></button></li>
            </ul>
        </transition>
		
	</div>
</template>

<script>
	export default {
		data() {
			return {
				expanded: false,
			}
		},
		mounted(){
			
		},
		methods: {
			//退出登录
			logOut(){
				this.$emit('logOut');
			},
			onMenuToggle(event) {
				this.$emit('menu-toggle', event);
			},
			onClick(event){
				this.expanded = !this.expanded;
				event.preventDefault();
			},
			UserAccount() {
				this.$router.push({
					path: '/useraccount',
				})
			},
			getHelp(){
				this.$router.push({
					path: '/helpAndFeedBack',
				})
			}
		},
		computed: {
			user_nickname() {
				return this.$appState.user_info.nickname;
			},
			user_headimgurl() {
				return this.$appState.user_info.headimgurl;
			},
		}
	}
</script>

<style scoped>

</style>